import yup from '../libs/yup'
import { masks } from '../utils/masks'
import { validarCNPJ, validarCPF } from '../utils/validateDocuments'

export type EstablishmentCreateForm = {
  _id?: string
  name: string
  email: string
  phone: string
  type?: string
  document: string
}

export const establishmentEditSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  phone: yup.string().required().label('Telefone'),
})


export const establishmentCreateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().required().email().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  document: yup
    .string()
    .test('Documento Inválido', (val: any) => {
      val = masks.unmask(val);
      if (val.length > 12) return validarCNPJ(masks.unmask(val))

      return validarCPF(masks.unmask(val))
    })
    .required(),
})
