import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;
  height: 100%;
`


export const Status = {
  Container: styled.div`
    padding: 5px 15px;
    background-color: ${({ color }) => color};
    text-align: center;
    width: fit-content;
    border-radius: 10px;
  `,
  Label: styled.div`
    color: white;
    font-weight: 600;
  `,
  LabelType: styled.div<{ type: string }>`
    color: ${({ type }) => (type !== 'entrada' ? 'green' : 'red')};
    font-weight: 600;
  `,
}
