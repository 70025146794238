import styled, { css } from 'styled-components'

export interface DrawerProps {
  isMobile: boolean
  open: boolean
}

export const Container = styled.div<DrawerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  padding: 0 25px;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
  background-color: ${({ theme }) => theme.colors.drawer};
  transition: left 0.1s cubic-bezier(0.82, 0.085, 0.395, 0.895);

  ${({ open, isMobile }) =>
    !open &&
    isMobile &&
    css`
      left: -200px;
    `}
  ${({ open, isMobile }) => {
    if (isMobile && open)
      return css`
        position: absolute;
        width: 100%;
      `
  }}
`
export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 25px;
`

export const ContainerMobile = styled.div``

export const Content = styled.div<DrawerProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${({ isMobile, open }) =>
    isMobile &&
    open &&
    css`
      margin-top: 30px;
    `}
`
