import { useQuery } from '@tanstack/react-query'
import { api } from './api'
import { Phone } from './sales.service'

export type Client = {
  _id: string
  name: string
  email: string
  phone: Phone
  document: string
  createdAt: string
}

type Clients = {
  data: Client[]
  page: number
  petPage: number
  totalPages: number
  total: number
}

async function fetchClient(
  page?: number,
  perPage?: number,
  search?: string,
  sort?: string,
): Promise<Clients> {
  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (sort) {
    var keyNames = Object.keys(sort)
    sortName = keyNames[0]
    order = sort[sortName]
  }

  const res = await api.get('/clients/me', {
    params: {
      page,
      perPage,
      search,
      sort: sortName,
      order: order === 'desc' ? -1 : 1,
    },
  })
  return res.data
}

function useClient(
  page: number | undefined,
  perPage: number | undefined,
  search: string | undefined,
  sort?: string | undefined,
) {
  return useQuery(
    ['client', page, perPage, search, sort],
    () => fetchClient(page, perPage, search, sort),
    {
      keepPreviousData: true,
      staleTime: 5000,
    },
  )
}

export default useClient
