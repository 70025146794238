import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container, Form } from '../../../../components/commons'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modals/Modal'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import { WidthdrawForm, WidthdrawSchema } from '../../../../validators/transaction.schema'
import Button from '../../../../components/Button'
import { masks } from '../../../../utils/masks'
import { useEstablishmentStore } from '../../../../store/establishment'
import { Title } from './styles'
import SelectInput from '../../../../components/Input/SelectInput'

export interface CreateAccountProps {}

export interface ModalHandle {
  openFormModal: (client: any) => void
}

type ModalRef = React.ElementRef<typeof Modal>

let maskType: any = {
  document: 'document',
  telefone: 'phone',
  email: undefined,
  aleatorio: undefined,
}

const WithdrawModal: React.ForwardRefRenderFunction<ModalHandle, CreateAccountProps> = (
  {},
  ref,
) => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const establismentOwner = useEstablishmentStore.getState().establishment

  const [cliente, setCliente] = useState<any>(null)
  const [balance, setBalance] = useState(0)

  const modalRef = useRef<ModalRef>(null)

  const handleGetBalance = async (_id: string) => {
    try {
      setLoading(true)

      const { data: response } = await api.get(`/clients/${_id}/balance`)

      setBalance(response?.total)
      //setPix(response.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<{
    value: string
    type: string
    pix: string
  }>({
    defaultValues: {},
    resolver: yupResolver(WidthdrawSchema),
  })

  const type = watch('type')

  const handleSubmitLogin = async (formData: WidthdrawForm) => {
    try {
      setLoading(true)

      formData.value = masks.unmask(formData.value)
      formData.pix = masks.unmask(formData.pix)

      console.log(formData)

      if (Number(formData.value) > balance) {
        return toast.errorToast('Valor desejado maior do que o disponível')
      }

      let url = `/clients/${cliente?._id}/withdraw`

      await api.post(url, formData)

      modalRef.current?.closeModal()
      reset()
      toast.successToast('Saque realizado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao criar saque !')
    } finally {
      setLoading(false)
    }
  }

  React.useImperativeHandle(ref, () => ({
    openFormModal(cliente: any) {
      console.log(cliente)
      handleGetBalance(cliente?._id)
      setCliente(cliente)
      modalRef.current?.openModal()
    },
  }))

  return (
    <>
      <Modal title='Sacar' ref={modalRef}>
        <Container>
          <Title>Cliente: {cliente?.name}</Title>
          <Title>CPF: {cliente?.document}</Title>
          <Title>Email: {cliente?.email}</Title>
          <Title>
            Telefone: {cliente?.phone?.ddd}
            {cliente?.phone?.number}
          </Title>
          <Title>Balance: {masks.currencyAllPlatforms(balance)}</Title>

          <Form onSubmit={handleSubmit(handleSubmitLogin)}>
            <SelectInput
              placeholder='Tipo do Pix'
              error={errors?.type}
              options={[
                {
                  label: 'CPF/CNPJ',
                  value: 'document',
                },
                {
                  label: 'Telefone',
                  value: 'telefone',
                },
                {
                  label: 'E-mail',
                  value: 'email',
                },
                {
                  label: 'Aleatório',
                  value: 'aleatorio',
                },
              ]}
              control={control}
              name='type'
            />

            <Input
              type='Pix'
              placeholder='Pix'
              mask={maskType[type]}
              error={errors?.pix}
              control={control}
              name='pix'
            />
            <Input
              type='text'
              placeholder='Valor Desejado (R$)'
              mask='currency'
              error={errors?.value}
              control={control}
              name='value'
            />
            <Button
              label='Sacar'
              loading={loading}
              type='submit'
              variantType='block'
              color='secondary'
            />
          </Form>
        </Container>
      </Modal>
    </>
  )
}

export default React.forwardRef(WithdrawModal)
