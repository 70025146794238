import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../../components/Button'
import { Container, Form, Grid, GridItem, LabelSection, Row } from '../../../../components/commons'
import Input from '../../../../components/Input'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import { useCompanyStore } from '../../../../store/company'
import {
  CompanyCreateForm,
  companyWebhookSchema,
  WebHookDto,
} from '../../../../validators/company.schema'
import { GridCard, ContentFooter } from '../styles'

export interface IntegrationDataProps {}

const IntegrationData: React.FunctionComponent<IntegrationDataProps> = () => {
  const [loading, setLoading] = React.useState(false)
  const [loadingReset, setLoadingReset] = React.useState(false)
  const company = useCompanyStore((state) => state.company)

  const toast = useToast()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyCreateForm>({
    defaultValues: {
      ...company,
    },
    resolver: yupResolver(companyWebhookSchema),
  })

  const handleReset = async () => {

    try {
      setLoading(true)

      const { data: response } = await api.patch('/companies/reflesh-keys')

      useCompanyStore.getState().setCompany(response)
      toast.successToast('Chaves atualizada com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao atualizar Chaves !')
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitWebhook = async (formData: WebHookDto) => {
    try {
      setLoading(true)

      const { data: response } = await api.put('/companies', {
        sandBoxWebhook: formData.sandBoxWebhook,
        productionWebhook: formData.productionWebhook,
      })

      useCompanyStore.getState().setCompany(response)
      toast.successToast('Webhook atualizado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao editar webhook !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(handleSubmitWebhook)}>
        <GridCard flexDirection='column' gap={25}>
          <Row>
            <LabelSection>Chaves de Integração</LabelSection>
            <Button
              color='secondary'
              variantType='label'
              loading={loadingReset}
              label='Resetar chaves'
              onClick={handleReset}
            />
          </Row>

          <Grid flexDirection='column' gap={25}>
            <GridItem>
              <Input
                error={errors?.publicKeyProduction}
                control={control}
                copy
                disabled
                label='Produção - Chave PUBLICA'
                type='text'
                name='publicKeyProduction'
              />
            </GridItem>
            <GridItem>
              <Input
                error={errors?.privateKeyProduction}
                control={control}
                disabled
                copy
                label='Produção - Chave Privada'
                type='text'
                name='privateKeyProduction'
              />
            </GridItem>
            <GridItem>
              <Input
                error={errors?.publicKeySandBox}
                control={control}
                copy
                disabled
                label='SANDBOX - Chave PUBLICA'
                type='text'
                name='publicKeySandBox'
              />
            </GridItem>
            <GridItem>
              <Input
                error={errors?.privateKeySandBox}
                control={control}
                copy
                disabled
                label='SANDBOX - Chave Privada'
                type='text'
                name='privateKeySandBox'
              />
            </GridItem>
            <GridItem>
              <Input
                error={errors?.sandBoxWebhook}
                control={control}
                copy
                label='Produção - Webhook'
                type='text'
                name='sandBoxWebhook'
              />
            </GridItem>
            <GridItem>
              <Input
                error={errors?.productionWebhook}
                control={control}
                copy
                label='SANDBOX - Webhook'
                type='text'
                name='productionWebhook'
              />
            </GridItem>
          </Grid>
          <ContentFooter>
            <Button
              color='secondary'
              loading={loading}
              type='submit'
              variantType='block'
              label='Salvar'
            />
          </ContentFooter>
        </GridCard>
      </Form>
    </Container>
  )
}

export default IntegrationData
