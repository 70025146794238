import React from 'react'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { theme } from '../../../styles/theme'
import { Container, ButtonIcon, ButtonPage } from './styles'

export interface PaginationProps {
  page: number
  perPage: number
  pageChangeHandler: (page: number) => void
  totalPages?: number
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  page,
  pageChangeHandler,
  totalPages = 1,
}) => {
  const noOfPages = totalPages

  const [canGoBack, setCanGoBack] = React.useState(false)
  const [canGoNext, setCanGoNext] = React.useState(true)

  const onNextPage = () => setCurrentPage(currentPage + 1)
  const onPrevPage = () => setCurrentPage(currentPage - 1)
  const onPageSelect = (pageNo: number) => setCurrentPage(pageNo)

  const [currentPage, setCurrentPage] = React.useState<number>(page)

  React.useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false)
    } else {
      setCanGoNext(true)
    }
    if (currentPage === 1) {
      setCanGoBack(false)
    } else {
      setCanGoBack(true)
    }
  }, [noOfPages, currentPage])

  React.useEffect(() => {
    pageChangeHandler(currentPage)
  }, [currentPage, pageChangeHandler])

  const getPageNumbers = () => {
    const pageNumbers = []

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i)
      }
    } else if (currentPage <= 4) {
      for (let i = 1; i <= 5; i++) {
        pageNumbers.push(i)
      }
      pageNumbers.push('...')
      pageNumbers.push(totalPages)
    } else if (currentPage >= totalPages - 3) {
      pageNumbers.push(1)
      pageNumbers.push('...')
      for (let i = totalPages - 4; i <= totalPages; i++) {
        pageNumbers.push(i)
      }
    } else {
      pageNumbers.push(1)
      pageNumbers.push('...')
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(i)
      }
      pageNumbers.push('...')
      pageNumbers.push(totalPages)
    }

    return pageNumbers
  }

  return (
    <Container>
      <ButtonIcon onClick={onPrevPage} disabled={!canGoBack}>
        <IoChevronBack size={20} color={theme.colors.primary} />
      </ButtonIcon>
      {getPageNumbers().map((num: any, index) => (
        <ButtonPage
          selected={page === num}
          key={index}
          onClick={() => num !== '...' && onPageSelect(num)}
        >
          {num === '...' ? num : num.toString()}
        </ButtonPage>
      ))}
      <ButtonIcon onClick={onNextPage} disabled={!canGoNext}>
        <IoChevronForward size={20} color={theme.colors.primary} />
      </ButtonIcon>
    </Container>
  )
}

export default Pagination
