import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { registerSchema, UserRegisterForm } from '../../../validators/auth.schemas'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Container, ContentCenter, Form, Title } from '../../../components/commons'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../services/api'
import { useUserStore } from '../../../store/user'
import { useAuthStore } from '../../../store/auth'
import useToast from '../../../hooks/useToast'

export interface RegisterProps {}

const Register: React.FunctionComponent<RegisterProps> = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<boolean>(false)

  const toast = useToast()

  const { setToken } = useAuthStore((state) => state)
  const { setUser } = useUserStore((state) => state)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserRegisterForm>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(registerSchema),
  })

  const handleSubmitRegister = async (formData: UserRegisterForm) => {
    try {
      setLoading(true)

      await api.post('/signup', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      })

      const { data: response } = await api.post('/login', {
        email: formData.email,
        password: formData.password,
      })

      setUser(response.data)
      setToken(response.token)

      toast.successToast('Login realizado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao efetuar o login !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Title>Register</Title>
      <Form onSubmit={handleSubmit(handleSubmitRegister)}>
        <Input
          placeholder='Digite seu nome'
          error={errors?.name}
          control={control}
          label='Nome'
          type='text'
          name='name'
        />
        <Input
          placeholder='Digite seu email'
          error={errors?.email}
          control={control}
          label='E-mail'
          type='email'
          name='email'
        />
        <Input
          placeholder='Digite sua senha'
          error={errors?.password}
          control={control}
          label='Senha'
          type='password'
          name='password'
        />
        <Input
          placeholder='Confirme sua senha'
          error={errors?.confirmPassword}
          control={control}
          label='Confirme sua senha'
          type='password'
          name='confirmPassword'
        />
        <Button
          label='Registrar'
          loading={loading}
          type='submit'
          fullWidth
          variantType='block'
          color='secondary'
        />
        <ContentCenter>
          <Button
            onClick={() => navigate('/login')}
            label='Logar'
            variantType='label'
            color='primary'
          />
        </ContentCenter>
      </Form>
    </Container>
  )
}

export default Register
