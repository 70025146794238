import React from 'react'

import { CardCompany } from './styles'
import { useCompanyStore } from '../../../store/company'
import { masks } from '../../../utils/masks'
import { ButtonIcon } from '../../Table/Pagination/styles'
import { FaEdit } from "react-icons/fa";
import { theme } from '../../../styles/theme'
import { useNavigate } from 'react-router-dom'

export interface HeaderProps {}

const CompanyChange: React.FunctionComponent<HeaderProps> = () => {
  const { company } = useCompanyStore((store) => store)

  const nav = useNavigate();

  return (
    <CardCompany.Content>
      <CardCompany.Row>
        <div>
          <CardCompany.Name>{company?.name}</CardCompany.Name>
          <CardCompany.Document>{company?.document && masks.document(company?.document)}</CardCompany.Document>
        </div>
        <ButtonIcon onClick={() => nav('/my-company')}>
          <FaEdit color={theme.colors.primary} />
        </ButtonIcon>
      </CardCompany.Row>
    </CardCompany.Content>
  )
}

export default CompanyChange
