import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
      flex-wrap: wrap;
  justify-content: flex-end;
  gap: 15px;

`

export const Item = styled.div`
  min-width: 200px;
  max-width: 500px;
`
