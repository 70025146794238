import { ThreeDots } from 'react-loader-spinner'
import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import ripple from '../../styles/animations/ripple'
import { theme } from '../../styles/theme'

const LoadingComp: React.FunctionComponent = () => (
  <ThreeDots
    wrapperStyle={{ justifyContent: 'center' }}
    color={theme.colors.secondary}
    width={100}
    height={30}
  />
)

export const LoaderComponent = styled(LoadingComp)<any>`
  svg {
    margin: auto !important;
  }
`
export const Content = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  max-width: 100%;
  display: block;
  background-color: #fff;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
`
export const TableGrid = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;

  border-spacing: 0 10px;

  width: 100%;

  ${down('md')} {
    table-layout: fixed;
    margin: 0;
    border: 0;
  }
`

export const HeaderTable = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: row;

`

export const HeaderTableRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`

export const SortContent = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 0px;
`


export const ButtonCreate = styled.div`
  ${ripple}
  cursor: pointer;
`
export const HeaderMobile = styled.tbody`
  display: none;
  ${down('md')} {
    display: flex;
    margin-bottom: 5px;
    font-weight: bold;
  }
`



export const TitleTable = styled.h1`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const Tbody = styled.tbody`
  border-spacing: 0 10px;
  border-collapse: separate;
  tr {
    background: #ffffff;
  }
`
export const Tr = styled.tr`
  border-radius: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};

  ${down('md')} {
    display: block;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`

export const Th = styled.th`
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  ${down('md')} {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`

export const Td = styled.td<{ active: boolean, onOpenRow: boolean}>`
  padding: 15px 10px;
  white-space: pre-wrap;
  text-align: left;
  font-size: 14px;
  ${({ active }) => !active && `opacity: 0.5;`}
  ${({ onOpenRow }) => onOpenRow && css`
    cursor: pointer;
  `}

  ${down('md')} {
    display: block;

    &:before {
      content: attr(data-label);
      float: left;

      font-weight: 500;

      margin-right: 10px;
      vertical-align: middle;
    }
  }
`

export const Thead = styled.thead`
  background-color: #fbfbfb !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border}!important;
`

export const Empty = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 25px;
`
