import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { Container } from '../../../components/commons'
import Table from '../../../components/Table'
import Pagination from '../../../components/Table/Pagination'
import useUsers, { User } from '../../../services/users.service'
import { format } from 'date-fns'

export interface UsersProps {}

const Users: React.FunctionComponent<UsersProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')
  const [sort, setSort] = React.useState<any>()

  const { isLoading, data } = useUsers(page, 18, search, sort)

  const onSortElement = (name: string, sort: string) => {
    setSort({ [name]: sort })
  }

  const onSearch = (value: string) => {
    setSearch(value)
  }

  const columnHelper = createColumnHelper<User>()

  const pageChangeHandler = (page: number) => setPage(page)
  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Nome',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('email', {
      header: () => 'Email',
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Data',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() !== null &&
        format(new Date(info.renderValue() || ''), 'dd/MM/yyyy HH:mm'),
    }),
  ]

  return (
    <Container>
      <Table
        title='Usuarios'
        onSort={onSortElement}
        onSearch={onSearch}
        isLoading={isLoading}
        columns={columns}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
    </Container>
  )
}

export default Users
