export const typeNames = (type: string) => {
  if (type === '') return ''

  const names: any = {
    balance: 'Saldo em conta',
    credit: 'Cartão de Crédito',
    debit: 'Cartão de Débito',
    debit2: 'Cartão de Débito 2',
    pix: 'Pix',
    boleto: 'Boleto',
  }

  return names[type]
}

export const typetransactionNames = (type: string) => {
  if (type === '') return ''

  const names: any = {
    deposit: 'Entrada',
    withdraw: 'Saída',
    antecipate: "Antecipação",
    'balance-extra': 'Saldo de ajuste'
  }

  return names[type]
}

export const statusNames = (status: string) => {
  if (status === '') return ''

  const names: any = {
    pending: {
      label: 'Pendente',
      color: '#ffa500',
    },
    refunded: {
      label: 'Reembolso',
      color: '#0e4bef',
    },
    'partial-refunded': {
      label: 'Reembolso Parcial',
      color: '#0e4bef',
    },
    declined: {
      label: 'Cartão Recusado',
      color: '#e71837',
    },
    canceled: {
      label: 'Cancelado',
      color: '#e71837',
    },
    finished: {
      label: 'Finalizado',
      color: '#49b675',
    },
  }

  return names[status]
}
