import Routes from './routes/routes'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { theme } from './styles/theme'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles/global'
import { IconContext } from 'react-icons'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {
  return (
    <>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <IconContext.Provider value={{ color: 'white' }}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Routes />
          </ThemeProvider>
        </IconContext.Provider>
      </QueryClientProvider>
    </>
  )
}

export default App
