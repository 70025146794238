/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Content, Error, Field, Label } from '../styles'
import { DateRangePicker } from 'react-date-range'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import ptBR from 'date-fns/locale/pt-BR'
import Popup from 'reactjs-popup'
import format from 'date-fns/format'

export interface DateRangeInputProps {
  placeholder?: string
  control: any
  label?: string
  error?: FieldError
  name: string
  defaultStartDate?: Date
  defaulEndDate?: Date
}

const DateRangeInput: React.FunctionComponent<DateRangeInputProps> = ({
  control,
  label,
  error,
  name,
  defaultStartDate,
  defaulEndDate,
}) => {
  const [inpout, setInput] = React.useState('')
  const [myState, setMyState] = React.useState(false)
  const [selectionRange, setSelectionRange] = React.useState<any>()

  React.useEffect(() => {
    if (myState === false && defaulEndDate && defaultStartDate) {
      setMyState(true)
      setSelectionRange({
        startDate: defaultStartDate,
        endDate: defaulEndDate,
        key: 'selection',
      })
      setInput(`${format(defaultStartDate, 'dd-MM-yyyy')} - ${format(defaulEndDate, 'dd-MM-yyyy')}`)
    } else if (myState === false) {
      setSelectionRange({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      })
    }
  }, [myState])

  if (!selectionRange) return null

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        const handleChange = (option: any) => {
          console.log('option', option)

          if (option.selection) {
            onChange(option.selection)
            setInput(
              `${format(option.selection.startDate, 'dd-MM-yyyy')} - ${format(
                option.selection.endDate,
                'dd-MM-yyyy',
              )}`,
            )
          }
        }
        return (
          <Content>
            {label && (
              <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                <Label>{label} </Label>
                <div
                  onClick={() => {
                    onChange(null)
                    setInput('')
                  }}
                  style={{ cursor: 'pointer', fontSize: 12 }}
                >
                  Limpar
                </div>
              </div>
            )}

            <Popup
              trigger={
                <Field
                  autoComplete='none'
                  role='presentation'
                  value={inpout}
                  placeholder='Selecionar'
                  error={!!error}
                  onBlur={onBlur}
                  onChange={() => console.log('')}
                />
              }
              position='bottom center'
            >
              <DateRangePicker
                locale={ptBR}
                ranges={value ? [value] : [selectionRange]}
                onChange={handleChange}
              />
            </Popup>

            {error && <Error>{error.message}</Error>}
          </Content>
        )
      }}
    />
  )
}

export default DateRangeInput
