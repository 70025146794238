import React from 'react'

import { CardCompany, Content } from './styles'

import useCompanies, { Company } from '../../../services/companies.service'
import { masks } from '../../../utils/masks'
import { LoaderComponent } from '../../../components/Table/styles'
import { Title } from '../../../components/commons'
import Button from '../../../components/Button'
import { useCompanyStore } from '../../../store/company'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../services/api'

export interface SelectCompanyrops {}

const SelectCompany: React.FunctionComponent<SelectCompanyrops> = () => {
  //const { isLoading, data } = useCompanies(1, 18, '')

  const nav = useNavigate()

  const { setCompany } = useCompanyStore((state) => state)

  const [companySelected, setCompanySelected] = React.useState<Company | undefined>(undefined)
  const [companies, setCompanies] = React.useState<Company[]>([])
  const [isLoading, setIsLoading] = React.useState(false)

  const handleGetCompany = async () => {
    try {
      setIsLoading(true)

      const { data: response } = await api.get(`/companies/me`)

      setCompanies(response.data)
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  }

  const handleSetCompany = async () => {
    try {
      if (companySelected) {
        api.defaults.headers['x-company-id'] = companySelected._id

        setCompany(companySelected)
        nav('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleJumpCreate = () => {
    nav('/create-company')
  }

  React.useEffect(() => {
    handleGetCompany()
  }, [])

  return (
    <Content>
      <Title>Selecione uma empresa</Title>
      {!isLoading &&
        companies.map((element) => (
          <CardCompany.Content
            onClick={() => setCompanySelected(element)}
            companySelected={companySelected?._id === element._id}
          >
            <CardCompany.Name companySelected={companySelected?._id === element._id}>
              {element.name}
            </CardCompany.Name>
            <CardCompany.Document companySelected={companySelected?._id === element._id}>
              {masks.document(element.document)}
            </CardCompany.Document>
          </CardCompany.Content>
        ))}
      {isLoading && <LoaderComponent />}
      {!isLoading && (
        <>
          <Button
            variantType='label'
            color='secondary'
            fullWidth
            onClick={() => handleJumpCreate()}
            label='Criar uma empresa'
          />

          <Button
            variantType='block'
            color='secondary'
            fullWidth
            isDisabled={!companySelected}
            onClick={() => handleSetCompany()}
            label='Continuar'
          />
        </>
      )}
    </Content>
  )
}

export default SelectCompany
