import React from 'react'
import { Container } from './styles'

export interface PublicProps {
  children: any
}

const Public: React.FunctionComponent<PublicProps> = ({ children }) => (
  <Container>{children}</Container>
)

export default Public
