import { useQuery } from '@tanstack/react-query'
import { api } from './api'

export type TransferAccount = {
  _id: string
  type: string
  account: string
  main: boolean
  createdAt: string
}

type TransferAccounts = {
  data: TransferAccount[]
  page: number
  petPage: number
  totalPages: number
  total: number
}

async function fetchBanking(
  page?: number,
  perPage?: number,
  search?: string,
  establishment?: string,
): Promise<TransferAccounts> {
  const res = await api.get(
    '/transfer-accounts',
    {
      params: {
        page,
        perPage,
        search,
        establishment: establishment
      },
    },
  )
  return res.data
}

function useBanking(
  page: number | undefined,
  perPage: number | undefined,
  search: string | undefined,
  establishment?: string,
) {
  return useQuery(
    ['banking', page, perPage, search, establishment],
    () => fetchBanking(page, perPage, search, establishment),
    {
      keepPreviousData: true,
      staleTime: 5000,
    },
  )
}

export default useBanking
