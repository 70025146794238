import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,

  Row: styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
  `,
  Collumn: styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
  `,
  Message: styled.div`
    font-size: 12px;
  `,
  Label: styled.div`
    font-size: 14px;
  `,
  Title: styled.div`
    font-size: 18px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: bold;
  `,
}

export const Menu = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding: 15px 0px;
    gap: 15px;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  `,
}
