import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container, Form } from '../../../../components/commons'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modals/Modal'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import SelectInput from '../../../../components/Input/SelectInput'
import { PixCreateForm, createPixSchema } from '../../../../validators/transaction.schema'
import Button from '../../../../components/Button'
import { masks } from '../../../../utils/masks'

export interface CreateAccountProps {
  reload: () => void
  establishmentId?: string
}

export interface ModalHandle {
  openFormModal: (initialData?: PixCreateForm) => void
}

type ModalRef = React.ElementRef<typeof Modal>

const CreateAccountPix: React.ForwardRefRenderFunction<ModalHandle, CreateAccountProps> = (
  { reload, establishmentId },
  ref,
) => {
  const [loading, setLoading] = useState(false)

  const [initialData, setInitialData] = useState<PixCreateForm>({
    type: '',
    account: '',
  })

  const toast = useToast()

  const modalRef = useRef<ModalRef>(null)

  const handleOpenModalCreate = () => {
    modalRef.current?.openModal()
  }

  const {
    setValue,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<PixCreateForm>({
    defaultValues: {
      type: initialData.type,
      account: initialData.account,
    },
    resolver: yupResolver(createPixSchema),
  })

  const type = watch('type')

  let maskType: any = {
    document: 'document',
    telefone: 'phone',
    email: undefined,
    aleatorio: undefined,
  }

  const handleSubmitLogin = async (formData: PixCreateForm) => {
    try {
      setLoading(true)

      if (['document', 'telefone'].includes(formData.type)) {
        formData.account = masks.unmask(formData.account)
      }

      if (establishmentId) {
        formData.establishment = establishmentId
      }

      if (initialData?._id) {
        let url = `/transfer-accounts/${initialData?._id}`

        await api.put(url, formData)
      } else {
        let url = `/transfer-accounts`
        await api.post(url, formData)
      }

      modalRef.current?.closeModal()
      reload()
      reset()
      toast.successToast('Pix criado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao criar pix !')
    } finally {
      setLoading(false)
    }
  }

  React.useImperativeHandle(ref, () => ({
    openFormModal(initialData) {
      if (initialData) {
        setInitialData(initialData)
        setValue('type', initialData.type)
        setValue('account', initialData.account)
      } else {
        setInitialData({
          account: '',
          type: '',
        })
        reset()
      }
      handleOpenModalCreate()
    },
  }))

  return (
    <>
      <Modal title='Adicionar Chave PIX' ref={modalRef}>
        <Container>
          <Form onSubmit={handleSubmit(handleSubmitLogin)}>
            <SelectInput
              placeholder='Tipo do Pix'
              error={errors?.type}
              options={[
                {
                  label: 'CPF/CNPJ',
                  value: 'document',
                },
                {
                  label: 'Telefone',
                  value: 'telefone',
                },
                {
                  label: 'E-mail',
                  value: 'email',
                },
                {
                  label: 'Aleatório',
                  value: 'aleatorio',
                },
              ]}
              control={control}
              name='type'
            />

            <Input
              type='Pix'
              placeholder='Pix'
              mask={maskType[type]}
              error={errors?.account}
              control={control}
              name='account'
            />
            <Button
              label='Registrar'
              loading={loading}
              type='submit'
              variantType='block'
              color='secondary'
            />
          </Form>
        </Container>
      </Modal>
    </>
  )
}

export default React.forwardRef(CreateAccountPix)
