import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { Container, StatusColorName, TypeEarning } from '../../../components/commons'
import Table from '../../../components/Table'
import Pagination from '../../../components/Table/Pagination'
import useTransactions, { Transaction } from '../../../services/transactions.service'
import { masks } from '../../../utils/masks'
import Button from '../../../components/Button'
import { Header } from '../../Dashboard/DashCompany/Transactions/styles'
import { api } from '../../../services/api'
import { CardSaldo, TitleSaldo, ValueSaldo } from './styles'
import WithdrawModal from './WithdrawModal'
import ReleaseBalanceModal from './ReleaseBalanceModal'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { useEstablishmentStore } from '../../../store/establishment'
import Filters from '../../../components/Filters'
import { useCompanyStore } from '../../../store/company'

export interface TransactionsProps {}

export interface Balance {
  total: number
  pending: number
}

type ReleaseBalanceRef = React.ElementRef<typeof ReleaseBalanceModal>
type WithdrawModalRef = React.ElementRef<typeof WithdrawModal>

const Transactions: React.FunctionComponent<TransactionsProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')
  const [balance, setBalance] = React.useState<Balance>({
    total: 0,
    pending: 0,
  })
  const [sort, setSort] = React.useState<any>()
  const [filters, setFilters] = React.useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  })
  const establisment = useEstablishmentStore.getState().establishment
  const company = useCompanyStore((state) => state.company)

  const { isLoading, data, refetch, isFetching } = useTransactions(
    page,
    18,
    search,
    undefined,
    undefined,
    sort,
    filters,
  )

  const columnHelper = createColumnHelper<Transaction>()

  const modalReleaseBalance = React.useRef<ReleaseBalanceRef>(null)
  const modalWithdraw = React.useRef<WithdrawModalRef>(null)

  const pageChangeHandler = (page: number) => setPage(page)

  const onSortElement = (name: string, sort: string) => {
    setSort({ [name]: sort })
  }

  const handleChangeFilter = async (filter: any) => {
    setFilters({ ...filter })
  }

  const handleGetBalance = async () => {
    const { data: response } = await api.get(
      establisment ? `/establishments/${establisment._id}/balance` : '/companies/balance',
    )

    setBalance({
      total: response.total,
      pending: response.totalIsPending,
    })
  }

  React.useEffect(() => {
    handleGetBalance()
  }, [])

  const handleCreateReleaseBalance = () => {
    modalReleaseBalance.current?.openFormModal()
    handleGetBalance()
  }

  const reloadPage = () => {
    handleGetBalance()
    refetch()
  }

  const handleCreateWithdraw = () => {
    modalWithdraw.current?.openFormModal()
    handleGetBalance()
  }

  const columns = [
    columnHelper.accessor('_id', {
      header: () => 'Id',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('establishment', {
      header: () => 'Estabelecimento',
      cell: (info) => info.renderValue()?.name,
    }),
    columnHelper.accessor('value', {
      header: () => 'Valor Total',
      enableSorting: true,
      cell: (info) => info.renderValue() && masks.currencyAllPlatforms(info.renderValue()),
    }),
    columnHelper.accessor('value', {
      header: () => 'Ganhos',
      enableSorting: true,
      cell: (info) => {
        return info.renderValue() && info.row.original.type !== 'deposit' ? (
          <>
            {establisment
              ? masks.currencyAllPlatforms(info.row.original.value - info.row.original.taxValue)
              : masks.currencyAllPlatforms(
                  info.row.original.companyTaxValue - info.row.original.taxValue,
                )}
          </>
        ) : (
          masks.currencyAllPlatforms(0)
        )
      },
    }),
    columnHelper.accessor('type', {
      header: () => 'Tipo',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() && (
          <TypeEarning type={info.row.original.payment ? 'deposit' : info.renderValue() || ''} />
        ),
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Data',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() !== null &&
        format(new Date(info.renderValue() || ''), 'dd/MM/yyyy HH:mm'),
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      enableSorting: true,
      cell: (info) => <StatusColorName status={info.renderValue() || ''} />,
    }),
  ]

  if (establisment) columns.splice(1, 1)

  return (
    <Container>
      <Filters
        filters={filters}
        statusArray={[
          {
            label: 'Pendente',
            value: 'pending',
          },
          {
            label: 'Cancelado',
            value: 'canceled',
          },
          {
            label: 'Finalizado',
            value: 'finished',
          },
        ]}
        tipoArray={[
          {
            label: 'Entrada',
            value: 'deposit',
          },
          {
            label: 'Saida',
            value: 'withdraw',
          },
          {
            label: 'Antecipação',
            value: 'antecipate',
          },
        ]}
        client
        establishment={!!company}
        rangeDate
        onChange={handleChangeFilter}
      />
      <ReleaseBalanceModal
        ref={modalReleaseBalance}
        establishmentId={establisment?._id}
        balance={balance}
        reload={reloadPage}
      />
      <WithdrawModal ref={modalWithdraw} balance={balance} reload={reloadPage} />
      <Header>
        <CardSaldo>
          <TitleSaldo>Saldo Bloqueado: </TitleSaldo>
          <ValueSaldo>{masks.currencyAllPlatforms(balance.pending)}</ValueSaldo>
        </CardSaldo>

        <CardSaldo>
          <TitleSaldo>Saldo: </TitleSaldo>
          <ValueSaldo>{masks.currencyAllPlatforms(balance.total)}</ValueSaldo>
        </CardSaldo>
      </Header>

      <Header>
        <Button
          color='success'
          variantType='block'
          label='Liberar Saldo'
          onClick={() => handleCreateReleaseBalance()}
        />
        <Button
          color='secondary'
          variantType='block'
          label='Sacar'
          onClick={() => handleCreateWithdraw()}
        />
      </Header>

      <Table
        title='Transações'
        onSort={onSortElement}
        isLoading={isLoading || isFetching}
        columns={columns}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
    </Container>
  )
}

export default Transactions
