import styled from 'styled-components'


export const TitleSaldo = styled.div`
  font-size: 16px;
`

export const ValueSaldo = styled.div`
  color: ${({theme }) => theme.colors.success};
  font-size: 16px;
`
export const CardSaldo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  gap: 5px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: #ffffff;
  align-items: center;
`

