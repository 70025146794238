import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { api } from '../services/api'

type State = {
  token?: string
  signed: boolean
}

type Actions = {
  setToken: (token: string) => void
  logout: () => void
}

export const useAuthStore = create(
  persist<State & Actions>(
    (set) => ({
      token: undefined,
      signed: false,
      setToken: (token: string) => {
        api.defaults.headers.Authorization = `Bearer ${token}`
        return set(() => ({
          token,
          signed: true,
        }))
      },
      logout: () => {
        delete api.defaults.headers.Authorization
        return set(() => ({
          token: undefined,
          signed: false,
        }))
      },
    }),
    {
      name: 'auth',
    },
  ),
)
