import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { api } from '../services/api'
import { Company } from '../services/companies.service'


type State = {
  company?: Company
}

type Actions = {
  setCompany: (user?: Company) => void
  logout: () => void
}

export const useCompanyStore = create(
  persist<State & Actions>(
    (set) => ({
      company: undefined,
      setCompany: (company?: Company) => {
        if(company) {
          api.defaults.headers['x-company-id'] = company._id
        }

        return set(() => ({
          company,
        }))
      },
      logout: () => {
        delete api.defaults.headers['x-company-id']
        return set(() => ({
          company: undefined,
        }))
      },
    }),
    {
      name: 'company',
    },
  ),
)
