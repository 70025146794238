import { down } from 'styled-breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 25px;
  height: 60px;
  position: fixed;
  top: 0;
  padding-left: 220px;
  box-shadow: 0 0.125rem 0.625rem rgba(90,97,105,.12);
  left: 0;
  background-color: ${({ theme }) => theme.colors.header};
  z-index: 100;
  transition: padding-left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);

  ${down('md')} {
    margin-left: 0px;
    padding-left: 25px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  margin-right: 10px;
  svg {
    cursor: pointer;
  }
`

export const TitleHeader = styled.label`
  color: ${({ theme }) => theme.colors.label};
`
