import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { forgotSchema, UserForgotForm } from '../../../validators/auth.schemas'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Container, ContentCenter, Form, Title } from '../../../components/commons'
import { useNavigate } from 'react-router-dom'
import useToast from '../../../hooks/useToast'
import { api } from '../../../services/api'

export interface ForgotProps {}

const Forgot: React.FunctionComponent<ForgotProps> = () => {
  const navigate = useNavigate()

  const toast = useToast()
  const [loading, setLoading] = React.useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserForgotForm>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotSchema),
  })

  const onSubmit = async (formData: UserForgotForm) => {
    try {
      setLoading(true)

      await api.post('/forgot', formData)

      toast.successToast('Email enviado com sucesso !')
      return navigate('/login')
    } catch (error) {
      toast.errorToast('Erro ao recuperar senha, verifique o email e tente novamente !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Title>Recuperar senha</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder='Email'
          error={errors?.email}
          control={control}
          type='email'
          name='email'
        />

        <Button
          label='Recuperar'
          loading={loading}
          type='submit'
          variantType='block'
          color='secondary'
        />
        <ContentCenter>
          <Button
            onClick={() => navigate('/login')}
            label='Voltar'
            variantType='label'
            color='primary'
          />
        </ContentCenter>
      </Form>
    </Container>
  )
}

export default Forgot
