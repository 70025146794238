import React from 'react'
import { Navigate } from 'react-router-dom'
import Private from '../../pages/_layouts/Private'

import { useAuthStore } from '../../store/auth'
import { useCompanyStore } from '../../store/company'
import { useUserStore } from '../../store/user'
import { useEstablishmentStore } from '../../store/establishment'

export interface PrivateRouteProps {
  Component: React.FunctionComponent
  title?: string
  blockCompany?: boolean
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  Component,
  title,
  blockCompany,
}) => {
  const { signed } = useAuthStore((state) => state)
  const company = useCompanyStore((state) => state.company)
  const establishment = useEstablishmentStore((state) => state.establishment)

  const user = useUserStore((state) => state.user)

  if (blockCompany) {
    return (
      <Private blockCompany={blockCompany} title={title}>
        {signed ? <Component /> : <Navigate to='/login' />}
      </Private>
    )
  }

  let nonSigned = !company && !establishment

  return (
    <Private title={title}>
      {signed && (company || establishment || user?.role === 'admin') ? (
        <Component />
      ) : signed && nonSigned ? (
        <Navigate to='/my-company' />
      ) : (
        <Navigate to='/login' />
      )}
    </Private>
  )
}

export default PrivateRoute
