/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Content, Error, Label } from '../styles'
import ReactSelect from 'react-select'
import makeAnimated from 'react-select/animated'
import { theme } from '../../../styles/theme'
import { darken } from 'polished'

interface SelectOptions {
  value: string
  label: string
}

export interface SelectInputProps {
  placeholder?: string
  control: any
  label?: string
  error?: FieldError
  isLoading?: boolean
  filterOption?: boolean
  name: string
  options: SelectOptions[]
  isClearable?: boolean
  isSearchable?: boolean
  onInputChange?: (text: string) => void
  isMulti?: boolean
}

const SelectInput: React.FunctionComponent<SelectInputProps> = ({
  control,
  placeholder = 'Selecionar...',
  label,
  error,
  options,
  isLoading,
  name,
  isClearable = true,
  isSearchable,
  filterOption,
  onInputChange,
  isMulti,
}) => {
  const animatedComponents = makeAnimated()

  const customStyles = {
    option: (styles: any, { data, isDisabled, isSelected }: any) => ({
      ...styles,
      color: isSelected ? 'white' : theme.colors.input,
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : '#fff'),
      },

      cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 999,
      fontSize: 14,
    }),
    singleValue: (styles: any) => ({
      ...styles,
      fontSize: 14,
      color: theme.colors.input,
      zIndex: 999,
    }),
    control: (styles: any) => ({
      ...styles,
      minHeight: 45,
      maxHeight: 80,
      fontSize: 14,
      borderRadius: 4,
      border: `1px solid ${theme.colors.border};`,
      ':hover': {
        border: `1px solid ${theme.colors.border};`,
        boxShadow: 'none',
      },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: darken(-0.6, theme.colors.placeholder),
      fontSize: 14,
    }),
    input: (styles: any) => ({
      ...styles,
      fontSize: 14,
      color: theme.colors.input,
    }),
  }

  const selectProps: any = {
    instanceId: name,
    classNamePrefix: 'react-select',
    isMulti,
    isSearchable,
    isClearable,
    options,
    isLoading,
    placeholder,
    onInputChange: onInputChange ? (text: string) => onInputChange(text) : () => {},
    components: animatedComponents,
    noOptionsMessage: () => 'Nenhuma opcão',
  }

  if(filterOption) {
    selectProps.filterOption = null;
  }
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        const handleChange = (option: any) => {

          if (option) {
            onChange(typeof option?.value === 'string' ? option.value : [...option] )
          } else {
            onChange(null)
          }
        }
        return (
          <Content>
            {label && <Label>{label}</Label>}

            <ReactSelect
              onChange={(value: any) => handleChange(value)}
              styles={customStyles}
              value={options.find((vl) => vl.value === value)}
              {...selectProps}
            />

            {error && <Error>{error.message}</Error>}
          </Content>
        )
      }}
    />
  )
}

export default SelectInput
