import React from 'react'

import { Container, MenuStyle } from './styles'
import { useAuthStore } from '../../../store/auth'
import { useUserStore } from '../../../store/user'
import { AvatarImage } from '../../commons'
import Popup from 'reactjs-popup'
import { useNavigate } from 'react-router-dom'
import { useCompanyStore } from '../../../store/company'
import { useEstablishmentStore } from '../../../store/establishment'

export interface HeaderProps {}

const Menu: React.FunctionComponent<HeaderProps> = () => {
  const { user } = useUserStore((store) => store)


  const nav = useNavigate();

  const logoutClean = () => {
    useAuthStore.getState().logout()
    useUserStore.getState().logout()
    useCompanyStore.getState().logout()
    useEstablishmentStore.getState().logout()
    nav('/login')
  }

  const menuRoutes: any = {
    Perfil: () => nav('/profile'),
    Sair:() => logoutClean(),
  }

  

  return (
    <Container>
      <Popup
        trigger={<AvatarImage src={user?.photo} width={40} height={40} />}
        position='bottom center'
      >
        <MenuStyle.Menu>
          {Object.keys(menuRoutes).map((element) => {
              return (
                <MenuStyle.Item onClick={() => menuRoutes[element]()}>
                  {element}
                </MenuStyle.Item>
              )
          })}
        </MenuStyle.Menu>
      </Popup>
    </Container>
  )
}

export default Menu
