import styled from 'styled-components'
import fadeIn from '../../styles/animations/fadeIn'
import defaultImage from '../../assets/images/default-image.png'
import ripple from '../../styles/animations/ripple'
import { statusNames, typetransactionNames } from '../../utils/namesTranslate'
import { Status } from '../../pages/Company/DashEstablishment/Sales/styles'
import { down } from 'styled-breakpoints'

export interface GridInterface {
  flexDirection?: string
  alignItems?: string
  justifyContent?: string
  gap?: number
}

export const LabelSection = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Container = styled.div`
  ${fadeIn};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Form = styled.form.attrs({
  autoComplete: 'off',
})`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`
export const Title = styled.h1`
  font-size: 25px;
  color: ${({ theme }) => theme.colors.title};
`
export const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Card = styled.div<{ minHeight?: number; row?: boolean }>`
  width: 100%;
  padding: 15px;
  display: flex;
  height: 100%;
  ${({ row }) => row ? `flex-direction: row;` : `flex-direction: column;`}
  gap: 10px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  background-color: #ffffff;
`

export const GridItem = styled.div<{ width?: number }>`
  ${({ width }) => `width: ${width || 100}%`};
  height: 100%;
  ${down('md')} {
    width: 100%;
  }
`

export const Grid = styled.div<GridInterface>`
  display: flex;
  width: 100%;
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ gap }) => `gap: ${gap}px`};

  ${down('md')} {
    flex-direction: column;
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  span {
    ${ripple}
    cursor: pointer;
  }
`

export const AvatarImage = styled.div<{ src?: string; width: number; height: number }>`
  ${ripple}
  background-image: ${({ src }) => (src ? `url("${src}")` : `url("${defaultImage}")`)};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 100%;
  cursor: pointer;
`

export const StatusColorName = ({ status, refundedValue = 0, value = 0 }: { status: string, refundedValue?: number, value?: number }) => {

  let statusAux = status

  if (refundedValue > 0 && refundedValue === value) {
    statusAux = 'refunded'
  } else if( refundedValue > 0 && refundedValue < value) {
    statusAux = 'partial-refunded'
  }

  return (
    <Status.Container color={statusNames(statusAux || '')?.color}>
      <Status.Label>{statusNames(statusAux || '')?.label}</Status.Label>
    </Status.Container>
  )
}

export const TypeEarning = ({ type }: { type: string }) => {
  return (
    <Status.Container>
      <Status.LabelType type={typetransactionNames(type || '')}>
        {typetransactionNames(type || '')}
      </Status.LabelType>
    </Status.Container>
  )
}
