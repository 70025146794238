import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;
  height: 100%;
`
