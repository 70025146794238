import React from 'react'
import Drawer from '../../../components/Drawer'
import Header from '../../../components/Header'
import { Container } from './styles'

export interface PrivateProps {
  children: any
  title?: string
  blockCompany?: boolean
}

type DrawerRef = React.ElementRef<typeof Drawer>

const Private: React.FunctionComponent<PrivateProps> = ({ children, blockCompany }) => {
  const refDrawer = React.useRef<DrawerRef>(null)

  const handleModal = () => refDrawer.current?.handleModal()

  return (
    <Container blockCompany={blockCompany}>
      {!blockCompany && (
        <>
          <Drawer ref={refDrawer} />
          <Header handleModal={handleModal}  />
        </>
      )}

      {children}
    </Container>
  )
}

export default Private
