import React from 'react'

import { Container, Content, Message, Link } from './styles'

export interface NotFoundProps {}

const NotFound: React.FunctionComponent<NotFoundProps> = () => (
  <Container>
    <Content>
      <Message>404</Message>
      <Link to='/'>Go to home</Link>
    </Content>
  </Container>
)

export default NotFound
