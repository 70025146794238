import React from 'react'
import { Navigate } from 'react-router-dom'
import Private from '../../pages/_layouts/Private'

import { useAuthStore } from '../../store/auth'
import { useCompanyStore } from '../../store/company'
import { useUserStore } from '../../store/user'
import { useEstablishmentStore } from '../../store/establishment'

export interface PrivateRouteAdminProps {
  Component: React.FunctionComponent
  title?: string
}

const PrivateRouteAdmin: React.FunctionComponent<PrivateRouteAdminProps> = ({
  Component,
  title,
}) => {
  const { signed } = useAuthStore((state) => state)
  const user = useUserStore((state) => state.user)
  const company = useCompanyStore((state) => state.company)
  const establishment = useEstablishmentStore((state) => state.establishment)

  return (
    <Private title={title}>
      {signed && user?.role === 'admin' ? (
        <Component />
      ) : signed && (company || establishment) ? (
        <Navigate to='/' />
      ) : signed && !company && !establishment ? (
        <Navigate to='/my-company' />
      ) : (
        <Navigate to='/login' />
      )}
    </Private>
  )
}

export default PrivateRouteAdmin
