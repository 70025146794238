import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UserDTO {
  name: string
  email: string
  photo: string
  role: string
}

type State = {
  user?: UserDTO
}

type Actions = {
  setUser: (user?: UserDTO) => void
  logout: () => void
}

export const useUserStore = create(
  persist<State & Actions>(
    (set) => ({
      user: undefined,
      setUser: (user?: UserDTO) => {
        return set(() => ({
          user,
        }))
      },
      logout: () => {
        return set(() => ({
          user: undefined,
        }))
      }
    }),
    {
      name: 'user',
    },
  ),
)
