import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ blockCompany?: boolean }>`
  min-height: 100%;
  max-width: 100%;
  display: flex;
  overflow-x: hidden;

  ${({ blockCompany }) =>
    !blockCompany &&
    css`
      margin-left: 200px;
    `}

  ${down('md')} {
    margin-left: 0px;
  }
  padding: 25px;
  padding-top: 85px;
`
