import { darken } from 'polished'
import styled, { css } from 'styled-components'
import fadeIn from '../../../styles/animations/fadeIn'

interface FieldProps {
  readonly error: boolean
}

export const Container = styled.div`
  ${fadeIn};
  height: 100%;
`

export const FieldArea = styled.textarea<FieldProps>`
  -webkit-appearance: none;
  font-size: 14px;
  padding: 5px 10px;
  height: 150px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.input};
  transition: border-color 200ms linear;
  border: none;
  :hover,
  :focus {
    border-color: ${({ theme }) => darken(0.2, theme.colors.border)};
  }

  &::placeholder {
    font-size: 14px;
    color: ${({ theme }) => darken(-0.7, theme.colors.placeholder)};
  }

  width: 100%;

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.error};

      &:hover {
        border-color: ${({ theme }) => darken(0.2, theme.colors.error)};
      }
    `};
`

export const Content = styled.div<any>`
  position: relative;
`

export const Label = styled.label<any>`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`
