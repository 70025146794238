import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { Container } from '../../../components/commons'
import Table from '../../../components/Table'
import Pagination from '../../../components/Table/Pagination'
import useCompanies, { Company } from '../../../services/companies.service'
import { masks } from '../../../utils/masks'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'

export interface CompaniesProps {}

const Companies: React.FunctionComponent<CompaniesProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')
  const [sort, setSort] = React.useState<any>()


  const { isLoading, data } = useCompanies(page, 18, search, sort)

  const nav = useNavigate();


  const onSortElement = (name: string, sort: string) => {
    setSort({ [name]: sort })
  }


  const onSearch = (value: string) => {
    setSearch(value)
  }

  const columnHelper = createColumnHelper<Company>()

  const handleOpenRow = async (_id: string) => {
    nav(`/companies/${_id}`)
  }

  const pageChangeHandler = (page: number) => setPage(page)
  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Nome',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('email', {
      header: () => 'Email',
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('document', {
      header: () => 'CPF/CNPJ',
      cell: (info) => masks.document(info.renderValue()),
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Data',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() !== null &&
        format(new Date(info.renderValue() || ''), 'dd/MM/yyyy HH:mm'),
    }),
  ]

  return (
    <Container>
      <Table
        title='Empresas'
        onSort={onSortElement}
        onSearch={onSearch}
        onOpenRow={handleOpenRow}
        isLoading={isLoading}
        columns={columns}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
    </Container>
  )
}

export default Companies
