import styled from 'styled-components'

export const CardTitle = styled.div`
  color: ${({theme }) => theme.colors.tile};
  font-size: 20px;
`

export const CardValue = styled.div`
  color: ${({theme }) => theme.colors.success};
  font-size: 20px;
`

export const ContentCard = styled.div<{ row?: boolean }>`
  display: flex;
  padding: 15px;
  align-items: center;
  gap: 15px;
  justify-content: center;
  ${({ row }) => row ? `flex-direction: row;` : `flex-direction: column;`}

`


