import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`

export const ButtonPage = styled.button<{ selected: boolean }>`
  border: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 100px;
  color: ${({ theme }) => theme.colors.primary};
  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.colors.secondary};
      color: #FFFFFF;
    `}
`

export const ButtonIcon = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
      `}
  }
`
