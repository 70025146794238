import React from 'react'
import { theme } from '../../../styles/theme'
import { Field } from '../styles'

import { Content } from './styles'

export interface SearchInputProps {
  onSearch: (search: string) => void
  isLoading: boolean
  placeholder: string
}

const SearchInput: React.FunctionComponent<SearchInputProps> = ({ onSearch, placeholder }) => {
  const [searchPayload, setSearchPayload] = React.useState('')

  const handleChangeText = (value: string) => {
    setSearchPayload(value)
  }

  const _handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): any => {
    if (e.key === 'Enter') {
      e.preventDefault()

      onSearch(searchPayload)
    }
  }

  return (
    <Content>
      <Field
        style={{ border: `1px solid ${theme.colors.border}` }}
        onChange={({ target }) => handleChangeText(target.value)}
        placeholder={placeholder}
        onKeyDown={_handleKeyDown}
        error={false}
        value={searchPayload}
        type='text'
      />
    </Content>
  )
}

export default SearchInput
