import styled from 'styled-components'
import Modal from 'react-modal'

export const ModalComponent = styled(Modal).attrs({
  style: {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 120,
    },
  },
})`
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
  max-width: 450px;
  padding: 25px;
  overflow-y: auto;
  height: fit-content;

  margin: auto;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.background};
`
export const Content = styled.div<{ maxWidth?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 44, 66, 0.5);
  svg {
    cursor: pointer;
  }
`

export const TitleHeader = styled.label`
  font-size: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.title};
`
