import React from 'react'
import { Navigate } from 'react-router-dom'

import Public from '../../pages/_layouts/Public'
import { useAuthStore } from '../../store/auth'
import { useUserStore } from '../../store/user'

export interface AuthRouteProps {
  Component: React.FunctionComponent
}

const AuthRoute: React.FunctionComponent<AuthRouteProps> = ({ Component }) => {
  const { signed } = useAuthStore((state) => state)
  const user = useUserStore((state) => state.user)

  return (
    <Public>
      {!signed ? (
        <Component />
      ) : user?.role === 'admin' ? (
        <Navigate
          to='/dashboard'
        />
      ) : (
        <Navigate to='/' />
      )}
    </Public>
  )
}

export default AuthRoute
