import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { loginSchema, UserRegisterForm } from '../../../validators/auth.schemas'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Container, ContentCenter, Form, Title } from '../../../components/commons'
import { useNavigate } from 'react-router-dom'
import useToast from '../../../hooks/useToast'
import { api } from '../../../services/api'
import { useAuthStore } from '../../../store/auth'
import { useUserStore } from '../../../store/user'
import { useEstablishmentStore } from '../../../store/establishment'

export interface LoginProps {}

const Login: React.FunctionComponent<LoginProps> = () => {
  const navigate = useNavigate()

  const toast = useToast()
  const [loading, setLoading] = React.useState<boolean>(false)

  const { setToken } = useAuthStore((state) => state)
  const { setUser } = useUserStore((state) => state)
  const setEstablishment = useEstablishmentStore.getState().setEstablishment

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserRegisterForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  })

  const handleSubmitLogin = async (formData: UserRegisterForm) => {
    try {
      setLoading(true)

      const { data: response } = await api.post('/login', formData)

      api.defaults.headers['Authorization'] = `Bearer ${response.token}`

      const { data: responseEstabliment } = await api.get('/establishments/me', {
        params: {
          perPage: 999,
        },
      })

      if (responseEstabliment.data.length > 0) {
        const { data: establishment } = await api.get(
          `/establishments/${responseEstabliment.data[0]._id}`,
        )

        setEstablishment(establishment)
      }

      setUser(response.data)
      setToken(response.token)

      if (response.data.role !== 'admin') {
        navigate('/my-company')
      }

      toast.successToast('Login realizado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao efetuar o login !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Title>Login</Title>
      <Form onSubmit={handleSubmit(handleSubmitLogin)}>
        <Input
          placeholder='Digite seu email'
          error={errors?.email}
          control={control}
          label='E-mail'
          type='email'
          name='email'
        />
        <Input
          placeholder='Digite sua senha'
          error={errors?.password}
          control={control}
          label='Senha'
          type='password'
          name='password'
        />
        <div style={{ marginLeft: 'auto' }}>
          <Button
            onClick={() => navigate('/forgot')}
            label='Esqueci a senha'
            variantType='label'
            color='primary'
          />
        </div>
        <Button
          label='logar'
          loading={loading}
          type='submit'
          fullWidth
          variantType='block'
          color='secondary'
        />
        <ContentCenter>
          <Button
            onClick={() => navigate('/register')}
            label='Registrar'
            variantType='label'
            color='primary'
          />
        </ContentCenter>
      </Form>
    </Container>
  )
}

export default Login
