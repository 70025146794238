import { useQuery } from '@tanstack/react-query'
import { api } from './api'
import { useEstablishmentStore } from '../store/establishment'

export type Transaction = {
  _id: string
  value: number
  type: string
  establishment?: {
    name: string
  }
  company?: {
    name: string
  }
  client?: {
    name: string
  }
  status: string
  companyTax: number
  companyTaxValue: number
  payment?: string
  tax: number
  taxValue: number
  plataformTaxValue: number
  createdAt: string
}

type Transactions = {
  data: Transaction[]
  page: number
  petPage: number
  totalPages: number
  total: number
}

async function fetchTransactions(
  page?: number,
  perPage?: number,
  search?: string,
  establishment?: string,
  admin?: boolean,
  sort?: string,
  filter?: any

): Promise<Transactions> {
  const establismentOwner = useEstablishmentStore.getState().establishment

  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (sort) {
    var keyNames = Object.keys(sort)
    sortName = keyNames[0]
    order = sort[sortName]
  }
 
  const res = await api.get(
    admin
      ? '/report/transactions'
      : establismentOwner?._id
      ? `/establishments/${establismentOwner._id}/transaction`
      : '/companies/transaction',
    {
      params: {
        page,
        perPage,
        sort: sortName,
        order: order === 'desc' ? -1 : 1,
        search,
        establishment,
        ...filter
      },
    },
  )
  return res.data
}

function useTransactions(
  page: number | undefined,
  perPage: number | undefined,
  search: string | undefined,
  establishment?: string | undefined,
  admin?: boolean | undefined,
  sort?: string | undefined,
  filter?: any
) {
  return useQuery(
    ['transaction', page, perPage, search, establishment, admin, sort, filter],
    () => fetchTransactions(page, perPage, search, establishment, admin, sort, filter),
    {
      keepPreviousData: true,
      staleTime: 5000,
    },
  )
}

export default useTransactions
