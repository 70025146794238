/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { api } from '../../../services/api'
import SelectInput from '../../Input/SelectInput'
import { useParams } from 'react-router-dom'
import { useCompanyStore } from '../../../store/company'
import { useEstablishmentStore } from '../../../store/establishment'
import { useUserStore } from '../../../store/user'

export interface ClientProps {
  name: string
  control: any
}

const Client: React.FunctionComponent<ClientProps> = ({ control, name }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const establismentOwner = useEstablishmentStore.getState().establishment


  const user = useUserStore.getState().user

  const company = useCompanyStore(state => state.company)
  
  const { id } = useParams()

  const [search, setSearch] = React.useState('')

  const handleGetQuery = async () => {
    try {
      setIsLoading(true)

      const { data: response } = await api.get( company ? '/clients/me' : user?.role === 'admin' ? '/clients' : `/establishments/${establismentOwner?._id}/clients`, {
        params: {
          perPage: 25,
          search,
          company: company ? undefined : id
        },
      })

      setOptions(
        response.data.map((el: any) => ({
          label: el.name,
          value: el._id,
        })),
      )
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    handleGetQuery()
  }, [search])

  return (
    <>
      <SelectInput filterOption isLoading={isLoading} isMulti onInputChange={(text) => setSearch(text) } isSearchable label='Clientes' name={name} control={control} options={options} />
    </>
  )
}

export default Client
