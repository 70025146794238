
import { down } from 'styled-breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
 display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
  max-width: 400px;
  margin: auto;
  ${down('md')} {
    padding: 25px;
  }
`