import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container, Form } from '../../../../components/commons'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modals/Modal'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import SelectInput from '../../../../components/Input/SelectInput'
import { WidthdrawForm, WidthdrawSchema, createPixSchema } from '../../../../validators/transaction.schema'
import Button from '../../../../components/Button'
import { masks } from '../../../../utils/masks'
import { Balance } from '..'
import { useEstablishmentStore } from '../../../../store/establishment'

export interface CreateAccountProps {
  reload: () => void
  balance: Balance
}

export interface ModalHandle {
  openFormModal: (initialData?: WidthdrawForm) => void
}

type ModalRef = React.ElementRef<typeof Modal>

const WithdrawModal: React.ForwardRefRenderFunction<ModalHandle, CreateAccountProps> = (
  { reload, balance },
  ref,
) => {
  const [loading, setLoading] = useState(false)
  const [pix, setPix] = useState([])
  const [loadingPix, setLoadingPix] = useState(false)
  const toast = useToast()
  const establismentOwner = useEstablishmentStore.getState().establishment

  const modalRef = useRef<ModalRef>(null)

  const handleOpenModalCreate = () => {
    modalRef.current?.openModal()
  }

  const handleGetPix = async () => {
    try {
      setLoadingPix(true)

      const { data: response } = await api.get('/transfer-accounts', {
        params:{
          perPage: 999,
          establishment: establismentOwner?._id
        }
      })

      setPix(response.data)
    } catch (error) {
    } finally {
      setLoadingPix(false)
    }
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WidthdrawForm>({
    defaultValues: {},
    resolver: yupResolver(WidthdrawSchema),
  })

  const handleSubmitLogin = async (formData: WidthdrawForm) => {
    try {
      setLoading(true)

      formData.value = masks.unmask(formData.value)


      if(Number(formData.value) > balance.total){
        return toast.errorToast("Valor desejado maior do que o disponível")
      }

      let url = establismentOwner
        ? `/establishments/${establismentOwner?._id}/withdraw`
        : `/companies/withdraw`

      await api.post(url, formData)

      modalRef.current?.closeModal()
      reload()
      reset()
      toast.successToast('Saque realizado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao criar saque !')
    } finally {
      setLoading(false)
    }
  }

  React.useImperativeHandle(ref, () => ({
    openFormModal() {
      reset()
      handleOpenModalCreate();
      handleGetPix()
    },
  }))

  return (
    <>
      <Modal title='Sacar' ref={modalRef}>
        <Container>
          <Form onSubmit={handleSubmit(handleSubmitLogin)}>
            <SelectInput
              placeholder='Pix'
              error={errors?.pix}
              label='Pix'
              options={pix.map((element: any) => ({
                label: element.account,
                value: element._id
              }))}
              control={control}
              name='pix'
            />

            <Input
              type='text'
              label='Valor Desejado (R$)'
              mask='currency'
              error={errors?.value}
              control={control}
              name='value'
            />
            <Button
              label='Sacar'
              loading={loading}
              type='submit'
              variantType='block'
              color='secondary'
            />
          </Form>
        </Container>
      </Modal>
    </>
  )
}

export default React.forwardRef(WithdrawModal)
