import React from 'react'
import { Container } from './styles'

export interface HeaderDrawerProps {}

const HeaderDrawer: React.FunctionComponent<HeaderDrawerProps> = () => {
  return <Container>
    
  </Container>
}

export default HeaderDrawer
