import styled from 'styled-components'
import ripple from '../../../styles/animations/ripple'

export const Container = styled.div`
  display: flex;

`

export const MenuStyle = {
  Container: styled.div`
    ${ripple}
    cursor: pointer;
  `,
  Menu: styled.div`
    gap: 15px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    min-width: 150px;
  `,
  Item: styled.div`
    ${ripple}
    cursor: pointer;
  `,
}

