import React from 'react'
import { Container, Label } from './styles'

export interface ItemProps {
  label: string
  to: string
  active: boolean
  navigatePage?: React.MouseEventHandler<HTMLButtonElement>
  disabled: boolean
}

const Item: React.FunctionComponent<ItemProps> = ({ label, navigatePage, active, disabled }) => {
  const activeButton = disabled ? false : active
  return (
    <Container onClick={navigatePage} disabled={disabled}>
      <Label active={activeButton}>{label}</Label>
    </Container>
  )
}

export default Item
