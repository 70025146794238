import React from 'react'
import { Grid, GridItem } from '../../../components/commons'
import { api } from '../../../services/api'
import { CardHome } from '../../Company/Home'

import { Container } from './styles'

export interface HomeProps {}

const Home: React.FunctionComponent<HomeProps> = () => {
  const [loading, setLoading] = React.useState(false)
  const [balance, setBalance] = React.useState(0)

  const handleGetBalance = async () => {
    setLoading(true)
    const { data: response } = await api.get('/report/balance')

    setBalance(response.total)
    setLoading(false)
  }

  React.useEffect(() => {
    handleGetBalance()
  }, [])

  return (
    <Container>
      <Grid flexDirection='row' gap={25}>
        <GridItem>
          <CardHome isLoading={loading} title='Saldo' value={balance} />
        </GridItem>
      </Grid>
    </Container>
  )
}

export default Home
