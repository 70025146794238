import styled from 'styled-components'

export const BalanceContent = {
  Label: styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
  `,
  Currency: styled.div`
    color: ${({ theme }) => theme.colors.success};
    font-size: 16px;
  `,
}
