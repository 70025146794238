import React from 'react'

import { Container } from './styles'
import useToast from '../../../../hooks/useToast'
import { TaxesDto, taxesEditSchema } from '../../../../validators/company.schema'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { api } from '../../../../services/api'
import { Establishment } from '../../../../services/establishment.service'
import { Form, Grid, GridItem, LabelSection } from '../../../../components/commons'
import { ContentFooter, GridCard } from '../../Configs/styles'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { useParams } from 'react-router-dom'

export interface TaxesProps {
  establishment: Establishment
  reflesh: () => void
}

const Taxes: React.FunctionComponent<TaxesProps> = ({ establishment, reflesh }) => {
  const [loading, setLoading] = React.useState(false)

  const toast = useToast()
  const { id } = useParams()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TaxesDto>({
    defaultValues: establishment?.taxes,
    resolver: yupResolver(taxesEditSchema),
  })


  const handleSubmitTaxes = async (formData: TaxesDto) => {
    try {
      setLoading(true)

      await api.put(`/establishments/${id}`, {
        taxes: formData,
      })

      reflesh()
      toast.successToast('Estabelecimento atualizada com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao editar Estabelecimento !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(handleSubmitTaxes)}>
        <GridCard flexDirection='column' gap={25}>
          <LabelSection>Taxas</LabelSection>
          <Grid flexDirection='row' gap={5}>
            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.credit}
                control={control}
                label='Cartão de Crédito (%)'
                type='text'
                name='credit'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.debit}
                control={control}
              
                label='Debito - Visa e MasterCard (%)'
                type='text'
                name='debit'
              />
            </GridItem>
          </Grid>
          <Grid flexDirection='row' gap={5}>
            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.debit2}
                control={control}
              
                label='Debito outras bandeiras (%)'
                type='text'
                name='debit2'
              />
            </GridItem>
            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.pix}
                control={control}
                label='Pix (%)'
              
                type='text'
                name='pix'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.boleto}
                control={control}
                label='Boleto (%)'
              
                type='text'
                name='boleto'
              />
            </GridItem>
          </Grid>

          <ContentFooter>
            <Button
              color='secondary'
              loading={loading}
              type='submit'
              variantType='block'
              label='Salvar'
            />
          </ContentFooter>
        </GridCard>
      </Form>
    </Container>
  )
}

export default Taxes
