import React from 'react'
import { Card, Grid, GridItem } from '../../../../components/commons'

import { Container } from './styles'

export interface HomeProps {}

const Home: React.FunctionComponent<HomeProps> = () => (
  <Container>
    <Grid flexDirection='row' gap={25}>
      <GridItem width={33}>
        <Card minHeight={250}>

        </Card>
      </GridItem>
      <GridItem width={33}>
        <Card minHeight={250}>

        </Card>
      </GridItem>
      <GridItem width={33}>
        <Card minHeight={250}>

        </Card>
      </GridItem>
    </Grid>
  </Container>
)

export default Home
