import React from 'react'

import { Container, Content } from './styles'
import { IoMenuOutline } from 'react-icons/io5'
import useIsMobile from '../../hooks/useIsMobile'
import Menu from './Menu'
import { useUserStore } from '../../store/user'
import CompanyChange from './CompanyChange'
import { theme } from '../../styles/theme'
import { useEstablishmentStore } from '../../store/establishment'

export interface HeaderProps {
  handleModal: () => void
}

const Header: React.FunctionComponent<HeaderProps> = ({handleModal }) => {
  const isMobile = useIsMobile()
  
  const user = useUserStore(state => state.user);
  const establishment = useEstablishmentStore(state => state.establishment);

  return (
    <Container>
      <Content>
        {isMobile && <IoMenuOutline onClick={handleModal} color={theme.colors.secondary} size={32} />}
      </Content>
      {
        user?.role !== 'admin' && !establishment && (
          <CompanyChange  />
        )
      }
      <Menu />
    </Container>
  )
}

export default Header
