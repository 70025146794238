import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { api } from '../services/api'
import { Establishment } from '../services/establishment.service'

type State = {
  establishment?: Establishment
}

type Actions = {
  setEstablishment: (establishment?: Establishment) => void
  logout: () => void
}

export const useEstablishmentStore = create(
  persist<State & Actions>(
    (set) => ({
      establishment: undefined,
      setEstablishment: (establishment?: Establishment) => {
        if(establishment) {
          api.defaults.headers['x-establishment-id'] = establishment._id
        }

        return set(() => ({
          establishment,
        }))
      },
      logout: () => {
        delete api.defaults.headers['x-establishment-id']
        return set(() => ({
          establishment: undefined,
        }))
      },
    }),
    {
      name: 'establishment',
    },
  ),
)
