import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/Button'
import { api } from '../../../services/api'
import { Establishment } from '../../../services/establishment.service'
import { masks } from '../../../utils/masks'
import Private from '../../_layouts/Private'

import { Container, Header, Menu } from './styles'
import { LoaderComponent } from '../../../components/Button/styles'
import { Company } from '../../../services/companies.service'
import { Balance } from '../../Company/Transactions'

export interface DashCompanyProps {
  Component: React.FunctionComponent<any>
}

const DashCompanies: React.FunctionComponent<DashCompanyProps> = ({ Component }) => {
  const [loading, setLoading] = React.useState(false)
  const [company, setCompany] = React.useState<Company>()
  const [balance, setBalance] = React.useState<Balance>({
    total: 0,
    pending: 0,
  })
  const { pathname } = useLocation()

  const { id } = useParams()

  const routes: any = [
    {
      label: 'Visão Geral',
      url: `/companies/${id}`,
      nav: () => nav(`/companies/${id}`),
    },
    {
      label: 'Vendas',
      url: `/companies/${id}/sales`,
      nav: () => nav(`/companies/${id}/sales`),
    },
    {
      label: 'Transações',
      url: `/companies/${id}/transactions`,
      nav: () => nav(`/companies/${id}/transactions`),
    },
    {
      label: 'Taxas',
      url: `/companies/${id}/taxes`,
      nav: () => nav(`/companies/${id}/taxes`),
    },
    {
      label: 'CONFIGURAÇÕES',
      url: `/companies/${id}/configs`,
      nav: () => nav(`/companies/${id}/configs`),
    },
  ]

  const nav = useNavigate()

  const handleGetCompanies = async () => {
    try {
      setLoading(true)

      if (id) {
        api.defaults.headers['x-company-id'] = id
      }

      const { data: response } = await api.get(`/companies/me`)

      setCompany(response)
    } catch (error) {
      nav('/companies')
    } finally {
      setLoading(false)
    }
  }

  const handleGetBalance = async () => {
    try {
      setLoading(true)

      if (id) {
        api.defaults.headers['x-company-id'] = id
      }

      const { data: response } = await api.get(`/companies/balance`)

      setBalance({
        total: response.total,
        pending: response.totalIsPending,
      })
    } catch (error) {
      nav('/companies')
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    handleGetCompanies()
    handleGetBalance()
  }, [])

  return (
    <Private>
      <Container>
        <Header.Container>
          <Header.Content>
            <Header.Collumn>
              <Header.Message>Você está no empresa:</Header.Message>
              <Header.Title>{company?.name}</Header.Title>
              <Header.Label>
                Documento: {masks.document(company?.document || '')} ID: {company?._id}
              </Header.Label>
            </Header.Collumn>

            <Header.Row>
              <Header.Collumn>
                <Header.Label>SALDO PENDENTE</Header.Label>
                <Header.Title>{masks.currencyAllPlatforms(balance?.pending)}</Header.Title>
              </Header.Collumn>
              <Header.Collumn>
                <Header.Label>SALDO ATUAL</Header.Label>
                <Header.Title>{masks.currencyAllPlatforms(balance?.total)}</Header.Title>
              </Header.Collumn>

            </Header.Row>
          </Header.Content>
        </Header.Container>
        <Menu.Container>
          {routes.map((element: any) => (
            <Button
              label={element.label}
              variantType='label'
              onClick={() => element.nav()}
              color={pathname === element.url ? 'secondary' : 'primary'}
            />
          ))}
        </Menu.Container>
        {company ? (
          <Component company={company} reflesh={handleGetCompanies} />
        ) : (
          <LoaderComponent />
        )}
      </Container>
    </Private>
  )
}

export default DashCompanies
