import React from 'react'
import { Container, Label, LoaderComponent } from './styles'

export interface ButtonProps {
  loading?: boolean
  label: string
  isDisabled?: boolean
  color: 'default' | 'error' | 'secondary' | 'primary' | 'success'
  type?: 'button' | 'submit' | 'reset' | undefined
  variantType: 'block' | 'outline' | 'label'
  fullWidth?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Button: React.FunctionComponent<ButtonProps> = ({
  label,
  variantType,
  color = 'default',
  fullWidth = false,
  type = 'button',
  isDisabled,
  onClick,
  loading,
}) => (
  <Container
    isDisabled={isDisabled || loading}
    fullWidth={fullWidth}
    loading={loading}
    color={color}
    variantType={variantType}
    type={type}
    onClick={onClick}
  >
    {loading ? <LoaderComponent color="white" /> : <Label> {label} </Label>}
  </Container>
)

export default Button
