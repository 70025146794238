import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import ModalDelete from '../../../../components/Modals/ModalDelete'
import CreateModal from '../../Banking/CreateModal'
import { useNavigate, useParams } from 'react-router-dom'
import useBanking, { TransferAccount } from '../../../../services/banking.service'
import { api } from '../../../../services/api'
import Button from '../../../../components/Button'
import TableActions from '../../../../components/Table/TableActions'
import { Container } from './styles'
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Table/Pagination'


export interface BankingProps {}
export type ModalDeleteRef = React.ElementRef<typeof ModalDelete>
type CreateAccountRef = React.ElementRef<typeof CreateModal>

const Banking: React.FunctionComponent<BankingProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')

  const { id } = useParams()

  const [loading, setLoading] = React.useState(false)
  const { isLoading, data, refetch } = useBanking(page, 18, search, id)

  const modalDeleteRef = React.useRef<ModalDeleteRef>(null)
  const modalCreate = React.useRef<CreateAccountRef>(null)

  const handleSubmitDelete = async (_id?: string) => {
    try {
      setLoading(true)
      await api.delete(`/transfer-accounts/${_id}/`)
      modalDeleteRef.current?.closeModal()
      refetch()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSearch = (value: string) => {
    setSearch(value)
  }

  const handleEditPrincipal = async (id: string) => {
    await api.put(`/transfer-accounts/${id}/`, {
      main: true
    })
    refetch()

  }

  const handleCreate = () => {
    modalCreate.current?.openFormModal()
  }

  const columnHelper = createColumnHelper<TransferAccount>()

  const pageChangeHandler = (page: number) => setPage(page)
  const columns = [
    columnHelper.accessor('account', {
      header: () => 'Pix',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('main', {
      header: () => 'Principal',
      cell: (info) => info.getValue() ? "Sim" : <Button
        label='Tornar Principal'
        onClick={() => handleEditPrincipal(info.row.original._id)}
        color='secondary'
        variantType='label'
      />,
    }),
    columnHelper.accessor('_id', {
      header: () => 'Ações',
      cell: (info) => {
        return (
          <TableActions
            handleDelete={() => modalDeleteRef.current?.openModal(info.row.original._id)}
          />
        )
      },
    }),
 
  ]


  return (
    <Container>
      <CreateModal ref={modalCreate} establishmentId={id} reload={refetch} />
      <ModalDelete
        loading={loading}
        title='Deletar essa chave PIX?'
        ref={modalDeleteRef}
        onSubmitButton={handleSubmitDelete}
      />
      <Table
        title='Chaves Pix'
        onSearch={onSearch}
        isLoading={isLoading}
        columns={columns}
        create={handleCreate}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
    </Container>
  )
}

export default Banking
