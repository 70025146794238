import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`

export const Content = styled.div`
  text-align: center;
`

export const Message = styled.div`
  font-size: 60px;
  color: #8e8e93;
`
