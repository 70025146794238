import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  forgotChangePasswordSchema,
  UserForgotChangePasswordForm,
} from '../../../validators/auth.schemas'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Container, ContentCenter, Form, Title } from '../../../components/commons'
import { useNavigate, useParams } from 'react-router-dom'
import useToast from '../../../hooks/useToast'
import { api } from '../../../services/api'

export interface ForgotChangePasswordProps {}

const ForgotChangePassword: React.FunctionComponent<ForgotChangePasswordProps> = () => {
  const navigate = useNavigate()

  const toast = useToast()
  const { token } = useParams()

  const [loading, setLoading] = React.useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserForgotChangePasswordForm>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(forgotChangePasswordSchema),
  })

  const onSubmit = async (formData: UserForgotChangePasswordForm) => {
    try {
      setLoading(true)

      await api.post('/changePassword', { password: formData.password, token })

      toast.successToast('Seu password foi trocado com sucesso !')
      return navigate('/login')
    } catch (error) {
      toast.errorToast('Erro ao trocar senha, verifique os dados e tente !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Title>Mudar senha</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder='Digite sua senha'
          error={errors?.password}
          control={control}
          label='Senha'
          type='password'
          name='password'
        />
        <Input
          placeholder='Confirme sua senha'
          error={errors?.confirmPassword}
          control={control}
          label='Confirme sua senha'
          type='password'
          name='confirmPassword'
        />

        <Button
          label='Recuperar'
          loading={loading}
          type='submit'
          variantType='block'
          color='secondary'
        />
        <ContentCenter>
          <Button
            onClick={() => navigate('/login')}
            label='Voltar'
            variantType='label'
            color='primary'
          />
        </ContentCenter>
      </Form>
    </Container>
  )
}

export default ForgotChangePassword
