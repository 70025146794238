import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useIsMobile from '../../hooks/useIsMobile'
import Item from './Item'
import { Container, Content } from './styles'
import { IoClose } from 'react-icons/io5'
import { useUserStore } from '../../store/user'
import HeaderDrawer from './HeaderDrawer'
import { useEstablishmentStore } from '../../store/establishment'
import { useCompanyStore } from '../../store/company'

export interface DrawerProps {
  title?: string
}

export interface DrawerHandle {
  handleModal: () => void
}

export interface RoutesProps {
  label: string
  to: string
  disabled?: boolean
  companyOnly?: boolean
}

const routesAdmin: RoutesProps[] = [
  {
    label: 'Dashboard',
    to: '/dashboard',
  },

  {
    label: 'Usuarios',
    to: '/users',
  },
  {
    label: 'Empresas',
    to: '/companies',
  },
  {
    label: 'Vendas',
    to: '/reports',
  },
  {
    label: 'Transações',
    to: '/earnings',
  },
]

const routesCompany: RoutesProps[] = [
  {
    label: 'Dashboard',
    to: '/',
  },
  {
    label: 'Vendas',
    to: '/sales',
  },
  {
    label: 'Transações',
    to: '/transactions',
  },
  {
    label: 'Estabelecimentos',
    to: '/establishments',
    companyOnly: true,
  },
  {
    label: 'Chaves Pix',
    to: '/transferAccounts',
    companyOnly: true,
  },
  {
    label: 'Clientes',
    to: '/clients',
    companyOnly: true,
  },
  {
    label: 'Configurações',
    to: '/configs',
  },
]

const Drawer: React.ForwardRefRenderFunction<DrawerHandle, DrawerProps> = ({ title }, ref) => {
  const isMobile: boolean = useIsMobile()
  const [open, setOpen] = React.useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const user = useUserStore((state) => state.user)
  const establishment = useEstablishmentStore((state) => state.establishment)
  const company = useCompanyStore((state) => state.company)

  React.useImperativeHandle(ref, () => ({
    handleModal() {
      setOpen((old) => !old)
    },
  }))

  const changePage = (to: string) => {
    if (pathname === '/' && to === '/') return true

    if (to === '/') return false

    return pathname.startsWith(to)
  }

  const routes = user?.role === 'admin' ? routesAdmin : routesCompany

  const onChangePage = (to: string) => {
    navigate(to)
    setOpen(false)
  }

  return (
    <Container open={open} isMobile={isMobile}>
      <Content open={open} isMobile={isMobile}>
        {isMobile && <IoClose onClick={() => setOpen((old) => !old)} size={45} />}

        <HeaderDrawer />
        {routes.map((item) => {
          if (item.companyOnly && company)
            return (
              <Item
                key={item.to}
                navigatePage={() => onChangePage(item.to)}
                active={changePage(item.to)}
                disabled={!!item.disabled}
                to={item.to}
                label={item.label}
              />
            )

          if (!item.companyOnly)
            return (
              <Item
                key={item.to}
                navigatePage={() => onChangePage(item.to)}
                active={changePage(item.to)}
                disabled={!!item.disabled}
                to={item.to}
                label={item.label}
              />
            )
        })}
      </Content>
    </Container>
  )
}

export default React.forwardRef(Drawer)
