import { useQuery } from '@tanstack/react-query'
import { api } from './api'
import { Transaction } from './transactions.service'
import { useEstablishmentStore } from '../store/establishment'

export interface Phone {
  ddd: number
  number: number
}

export type Sale = {
  _id: string
  value: number
  total:number
  refundedValue: number
  type: string
  establishment?: {
    name: string
    document: string
  }
  company?: {
    name: string
    document: string
  }
  client?: {
    name: string
    document: string
    email: string
    phone: Phone
  }
  status: 'pending' | 'declined' | 'canceled' | 'finished' | 'refunded' | 'partial-refunded'
  createdAt: string
  externalTransactionId: string
  chargeId: string
  transaction: Transaction
  linkTransaction?: string
  expirationDate?: string
  paymentDate?: string
  qrCodeText?: string
  qrCodeBase64?: string
  qrCodePng?: string
  barcode?: string
  boletoPdf?: string
  errorMessage?: string
}

type Sales = {
  data: Sale[]
  page: number
  petPage: number
  totalPages: number
  total: number
}

async function fetchPayments(
  page?: number,
  perPage?: number,
  search?: string,
  establishment?: string,
  admin?: boolean,
  sort?: string,
  filter?: any
): Promise<Sales> {
  const establismentOwner = useEstablishmentStore.getState().establishment

  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (sort) {
    var keyNames = Object.keys(sort)
    sortName = keyNames[0]
    order = sort[sortName]
  }

  const res = await api.get(
    !!admin
      ? '/report/sales'
      : establismentOwner?._id
      ? `/establishments/${establismentOwner._id}/payment`
      : '/companies/payment',
    {
      params: {
        page,
        perPage,
        sort: sortName,
        order: order === 'desc' ? -1 : 1,
        search,
        establishment,
        ...filter
      },
    },
  )
  return res.data
}

function useSales(
  page: number | undefined,
  perPage: number | undefined,
  search: string | undefined,
  establishment?: string | undefined,
  admin?: boolean,
  sort?: string | undefined,
  filter?: any
) {
  return useQuery(
    ['sales', page, perPage, search, establishment, admin, sort, filter],
    () => fetchPayments(page, perPage, search, establishment, admin, sort, filter),
    {
      keepPreviousData: true,
      staleTime: 5000,
    },
  )
}

export default useSales
