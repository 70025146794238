import styled from 'styled-components'
import { Grid, GridInterface } from '../../../components/commons'

export const GridCard = styled(Grid)<GridInterface>`
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
`

export const ContentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`

