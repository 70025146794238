import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container, Form } from '../../../../components/commons'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modals/Modal'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import {
  PixCreateForm,
  ReleaseForm,
  releaseSchema,
} from '../../../../validators/transaction.schema'
import Button from '../../../../components/Button'
import { masks } from '../../../../utils/masks'
import { BalanceContent } from './styles'
import { Balance } from '..'

export interface CreateAccountProps {
  reload: () => void
  balance: Balance
  establishmentId?: string
}

export interface ModalHandle {
  openFormModal: (initialData?: PixCreateForm) => void
}

type ModalRef = React.ElementRef<typeof Modal>

const ReleaseBalanceModal: React.ForwardRefRenderFunction<ModalHandle, CreateAccountProps> = (
  { reload, establishmentId, balance },
  ref,
) => {
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const modalRef = useRef<ModalRef>(null)

  const handleOpenModalCreate = () => {
    modalRef.current?.openModal()
  }

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ReleaseForm>({
    defaultValues: { value: '' },
    resolver: yupResolver(releaseSchema),
  })

  const value = watch('value')

  const handleSubmitLogin = async (formData: ReleaseForm) => {
    try {
      formData.value = masks.unmask(formData.value)

      if(Number(formData.value) > balance.pending){
        return toast.errorToast("Valor desejado maior do que o disponível")
      }

      setLoading(true)

      let url = establishmentId
        ? `/establishments/${establishmentId}/antecipate`
        : `/companies/antecipate`

      await api.post(url, formData)

      modalRef.current?.closeModal()
      reload()
      reset()
      toast.successToast('Antecipação criada com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao criar Antecipação !')
    } finally {
      setLoading(false)
    }
  }

  React.useImperativeHandle(ref, () => ({
    openFormModal() {
      reset()
      handleOpenModalCreate()
    },
  }))

  return (
    <>
      <Modal title='Antecipação' ref={modalRef}>
        <Container>
          <BalanceContent.Label>
            Disponível:
            <BalanceContent.Currency>
              {masks.currencyAllPlatforms(balance.pending)}
            </BalanceContent.Currency>
          </BalanceContent.Label>
          <BalanceContent.Label>
            Valor Bruto:
            <BalanceContent.Currency>
              {masks.currencyAllPlatforms(Number(masks.unmask(value)))}
            </BalanceContent.Currency>
          </BalanceContent.Label>
          <BalanceContent.Label>
            Total de Taxas:
            <BalanceContent.Currency>
              {masks.currencyAllPlatforms(Number(masks.unmask(value)) * 0.025)}
            </BalanceContent.Currency>
          </BalanceContent.Label>
          <BalanceContent.Label>
            Valor Antecipado:
            <BalanceContent.Currency>
              {masks.currencyAllPlatforms(Number(masks.unmask(value)) * 0.975)}
            </BalanceContent.Currency>
          </BalanceContent.Label>

          <Form onSubmit={handleSubmit(handleSubmitLogin)}>
            <Input
              type='text'
              label='Valor Desejado (R$)'
              mask='currency'
              error={errors?.value}
              control={control}
              name='value'
            />
            <Button
              label='Antecipar'
              loading={loading}
              type='submit'
              variantType='block'
              color='secondary'
            />
          </Form>
        </Container>
      </Modal>
    </>
  )
}

export default React.forwardRef(ReleaseBalanceModal)
