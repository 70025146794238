import styled, { css } from 'styled-components'
import fadeIn from '../../../styles/animations/fadeIn'
import ripple from '../../../styles/animations/ripple'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`

export const Content = styled.div`
  ${fadeIn}
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 500px;
  margin: auto;
`

export const CardCompany = {
  Content: styled.div<{ companySelected: boolean }>`
    ${ripple}
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.border};

    border-radius: 15px;
    ${({ companySelected }) =>
      companySelected &&
      css`
        background-color: ${({ theme }) => theme.colors.background};
        border: 1px solid ${({ theme }) => theme.colors.secondary};
      `}
    padding: 15px;
    width: 100%;
  `,
  Name: styled.div<{ companySelected: boolean }>`
    font-size: 18px;
    font-weight: bold;
  `,
  Document: styled.div<{ companySelected: boolean }>`
    font-size: 14px;
  `,
}
