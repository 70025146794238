import { darken } from 'polished'
import { ThreeDots } from 'react-loader-spinner'
import styled, { css } from 'styled-components'
import ripple from '../../styles/animations/ripple'

const LoadingComp: React.FunctionComponent = () => (
  <ThreeDots wrapperStyle={{ justifyContent: 'center' }} color={'white'} height={15} />
)
export interface ButtonProps {
  loading?: boolean
  fullWidth?: boolean
  color: string
  isDisabled?: boolean
  variantType: 'block' | 'outline' | 'label'
}

export const Container = styled.button<ButtonProps>`
  -webkit-appearance: none;

  ${ripple};
  height: 100%;
  cursor: pointer;
  border: none;

  padding: 0px 15px;
  text-align: center;
  transition: background-color 200ms linear, box-shadow 200ms linear, border-color 200ms linear;
  border-radius: 8px;
  ${({ loading, isDisabled }) =>
    (loading || isDisabled) &&
    css`
      cursor: not-allowed;
      pointer: normal;
      opacity: 60%;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      margin: auto;
    `}  

    ${({ color, variantType, theme }) => {
    if (variantType === 'label') {
      return css`
        color: ${theme.colors[color!]};
        background: transparent;
        display: flex;
        border-radius: 0px;
        width: fit-content;
        padding: 0px;
        align-items: center;

        &:not(:disabled):hover {
          color: ${darken(0.2, theme.colors[color!])};
        }
      `
    }
    if (variantType === 'outline') {
      return css`
        color: ${theme.colors[color!]};
        background: transparent;
        height: 55px;
        border: 1.5px solid ${theme.colors.border};
        &:not(:disabled):hover {
          border-color: ${darken(0.2, theme.colors.border)};
          color: ${darken(0.2, theme.colors[color!])};
        }
      `
    }
    return css`
      color: ${theme.colors.buttonLabel};
      background: ${theme.colors[color!]};
      height: 45px;
      &:not(:disabled):hover {
        background: ${darken(0.05, theme.colors[color!])};
      }
    `
  }}
`
export const Label = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
`

export const LoaderComponent = styled(LoadingComp)<any>`
  svg {
    margin: auto !important;
  }
`

