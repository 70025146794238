import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/Button'
import { api } from '../../../services/api'
import { Establishment } from '../../../services/establishment.service'
import { masks } from '../../../utils/masks'
import Private from '../../_layouts/Private'

import { Container, Header, Menu } from './styles'
import { LoaderComponent } from '../../../components/Button/styles'
import { Balance } from '../Transactions'

export interface DashEstablishmentProps {
  Component: React.FunctionComponent<any>
}

const DashEstablishment: React.FunctionComponent<DashEstablishmentProps> = ({ Component }) => {
  const [loading, setLoading] = React.useState(false)
  const [establishment, setEstablishment] = React.useState<Establishment>()
  const [balance, setBalance] = React.useState<Balance>({
    total: 0,
    pending: 0,
  })
  const { pathname } = useLocation()

  const { id } = useParams()

  const routes: any = [
    {
      label: 'Visão Geral',
      url: `/establishments/${id}`,
      nav: () => nav(`/establishments/${id}`),
    },
    {
      label: 'Vendas',
      url: `/establishments/${id}/sales`,
      nav: () => nav(`/establishments/${id}/sales`),
    },
    {
      label: 'Transações',
      url: `/establishments/${id}/transactions`,
      nav: () => nav(`/establishments/${id}/transactions`),
    },
    {
      label: 'Taxas',
      url: `/establishments/${id}/taxes`,
      nav: () => nav(`/establishments/${id}/taxes`),
    },
    {
      label: 'Chaves Pix',
      url: `/establishments/${id}/transferAccounts`,
      nav: () => nav(`/establishments/${id}/transferAccounts`),
    },
    {
      label: 'CONFIGURAÇÕES',
      url: `/establishments/${id}/configs`,
      nav: () => nav(`/establishments/${id}/configs`),
    },
  ]

  const nav = useNavigate()

  const handleGetEstablishment = async () => {
    try {
      setLoading(true)

      const response = await api.get(`/establishments/${id}`)

      setEstablishment(response.data)
    } catch (error) {
      nav('/establishments')
    } finally {
      setLoading(false)
    }
  }

  const handleGetBalance = async () => {
    try {
      setLoading(true)

      const { data: response } = await api.get(`/establishments/${id}/balance`)

      setBalance({
        total: response.total,
        pending: response.totalIsPending,
      })
    } catch (error) {
      nav('/establishments')
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    handleGetEstablishment()
    handleGetBalance()
  }, [])

  return (
    <Private>
      <Container>
        <Header.Container>
          <Header.Content>
            <Header.Collumn>
              <Header.Message>Você está no estabelecimento:</Header.Message>
              <Header.Title>{establishment?.name}</Header.Title>
              <Header.Label>
                Documento: {masks.document(establishment?.document || '')} ID: {establishment?._id}
              </Header.Label>
            </Header.Collumn>

            <Header.Row>
              <Header.Collumn>
                <Header.Label>SALDO PENDENTE</Header.Label>
                <Header.Title>{masks.currencyAllPlatforms(balance?.pending)}</Header.Title>
              </Header.Collumn>
              <Header.Collumn>
                <Header.Label>SALDO ATUAL</Header.Label>
                <Header.Title>{masks.currencyAllPlatforms(balance?.total)}</Header.Title>
              </Header.Collumn>
            </Header.Row>
          </Header.Content>
        </Header.Container>
        <Menu.Container>
          {routes.map((element: any) => (
            <Button
              label={element.label}
              variantType='label'
              onClick={() => element.nav()}
              color={pathname === element.url ? 'secondary' : 'primary'}
            />
          ))}
        </Menu.Container>
        {establishment ? (
          <Component establishment={establishment} reflesh={handleGetEstablishment} />
        ) : (
          <LoaderComponent />
        )}
      </Container>
    </Private>
  )
}

export default DashEstablishment
