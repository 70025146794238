import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, StatusColorName } from '../../../components/commons'
import Table from '../../../components/Table'
import Pagination from '../../../components/Table/Pagination'
import useSales, { Sale } from '../../../services/sales.service'
import { masks } from '../../../utils/masks'
import { typeNames } from '../../../utils/namesTranslate'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import Filters from '../../../components/Filters'
import { useCompanyStore } from '../../../store/company'

export interface SalesProps {}

const Sales: React.FunctionComponent<SalesProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')
  const [sort, setSort] = React.useState<any>()

  const company = useCompanyStore((state) => state.company)
  const [filters, setFilters] = React.useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  })
  const { isLoading, data, refetch, isFetching } = useSales(
    page,
    18,
    search,
    undefined,
    false,
    sort,
    filters,
  )

  const nav = useNavigate()

  const handleOpenRow = async (_id: string) => {
    nav(`/sales/${_id}`)
  }

  const handleChangeFilter = async (filter: any) => {
    setFilters({ ...filter })
  }

  const columnHelper = createColumnHelper<Sale>()

  const onSortElement = (name: string, sort: string) => {
    setSort({ [name]: sort })
  }

  const pageChangeHandler = (page: number) => setPage(page)

  const columns = [
    columnHelper.accessor('_id', {
      header: () => 'Id',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('establishment', {
      header: () => 'Estabelecimento',
      cell: (info) => info.renderValue()?.name,
    }),
    columnHelper.accessor('value', {
      header: () => 'Valor',
      enableSorting: true,
      cell: (info) => info.renderValue() && masks.currencyAllPlatforms(info.renderValue()),
    }),
    columnHelper.accessor('type', {
      header: () => 'Tipo',
      enableSorting: true,
      cell: (info) => info.renderValue() && typeNames(info.renderValue() || ''),
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Data',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() !== null &&
        format(new Date(info.renderValue() || ''), 'dd/MM/yyyy HH:mm'),
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      enableSorting: true,
      cell: (info) => (
        <StatusColorName
          status={info.renderValue() || ''}
          refundedValue={info.row.original.refundedValue}
          value={info.row.original.value}
        />
      ),
    }),
  ]

  return (
    <Container>
      <Filters
        filters={filters}
        statusArray={[
          {
            label: 'Pendente',
            value: 'pending',
          },
          {
            label: 'Reembolso',
            value: 'refunded',
          },
          {
            label: 'Reembolso Parcial',
            value: 'partial-refunded',
          },
          {
            label: 'Cartão Recusado',
            value: 'declined',
          },
          {
            label: 'Cancelado',
            value: 'canceled',
          },
          {
            label: 'Finalizado',
            value: 'finished',
          },
        ]}
        establishment={!!company}
        client
        tipoArray={[
          {
            label: 'Saldo em conta',
            value: 'balance',
          },
          {
            label: 'Cartão de Crédito',
            value: 'credit',
          },
          {
            label: 'Cartão de Débito',
            value: 'debit',
          },
          {
            label: 'Cartão de Débito 2',
            value: 'debit2',
          },
          {
            label: 'Pix',
            value: 'pix',
          },
          {
            label: 'Boleto',
            value: 'boleto',
          },
        ]}
        rangeDate
        onChange={handleChangeFilter}
      />
      <Table
        title='Vendas'
        onOpenRow={handleOpenRow}
        onSort={onSortElement}
        isLoading={isLoading || isFetching}
        columns={columns}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
    </Container>
  )
}

export default Sales
