export interface ThemeType {
  colors: any
  shadows: any
  sm: string
  md: string
  lg: string
  xl: string
}

export const theme: ThemeType = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  colors: {
    secondary: '#3d5170',
    primary: '#737373',
    text: '#3d5170',
    background: '#f5f6f8',
    placeholder: '#000000',
    title: '#3d5170',
    header: '#f5f6f8',
    default: '#1d222e',
    drawer: '#ffffff',
    label: '#3d5170',
    input: '#737373',
    border: '#e1e5eb',
    error: '#DE4848',
    warning: '#DEBD48',
    success: '#48DE84',
    buttonLabel: '#FFFFFF',
  },

  shadows: {
    mediumShadow: 'box-shadow: 0px 4px 14px rgba(132, 201, 250, 0.07);',
  },
}
