import { createColumnHelper } from '@tanstack/react-table'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from '../../../components/commons'
import ModalDelete from '../../../components/Modals/ModalDelete'
import Table from '../../../components/Table'
import Pagination from '../../../components/Table/Pagination'
import TableActions from '../../../components/Table/TableActions'
import { api } from '../../../services/api'
import useEstablishment, { Establishment } from '../../../services/establishment.service'
import { masks } from '../../../utils/masks'
import CreateModal from './CreateModal'
import { format } from 'date-fns'

type CreateEstablishmentRef = React.ElementRef<typeof CreateModal>
export type ModalDeleteRef = React.ElementRef<typeof ModalDelete>

export interface EstablishmentHomeProps {}

const EstablishmentHome: React.FunctionComponent<EstablishmentHomeProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')
  const [sort, setSort] = React.useState<any>()

  const { isLoading, data, refetch } = useEstablishment(page, 18, search, sort)
  const [loading, setloading] = React.useState(false)

  const nav = useNavigate();

  const modalCreate = useRef<CreateEstablishmentRef>(null)
  const modalDeleteRef = useRef<ModalDeleteRef>(null)

  const onSearch = (value: string) => {
    setSearch(value)
  }

  const onSortElement = (name: string, sort: string) => {
    setSort({ [name]: sort })
  }

  const handleSubmitDelete = async (_id?: string) => {
    try {
      setloading(true)
      await api.delete(`/establishments/${_id}`)
      modalDeleteRef.current?.closeModal()
      refetch()
    } catch (error) {
    } finally {
      setloading(false)
    }
  }

  const handleSubmitActive = async (_id: string, active: boolean) => {
    await api.put(`/establishments/${_id}`, { active })

    refetch()
  }

  const handleOpenRow = async (_id: string) => {
    nav(`/establishments/${_id}`)
  }
  

  const handleCreate = () => {
    modalCreate.current?.openFormModal()
  }

  const columnHelper = createColumnHelper<Establishment>()

  const pageChangeHandler = (page: number) => setPage(page)
  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Nome',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('email', {
      header: () => 'Email',
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('phone', {
      header: () => 'Telefone',
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('document', {
      header: () => 'CPF/CNPJ',
      cell: (info) => info.renderValue() && masks.document(info.renderValue()),
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Data',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() !== null &&
        format(new Date(info.renderValue() || ''), 'dd/MM/yyyy HH:mm'),
    }),
    columnHelper.accessor('_id', {
      header: () => 'Ações',
      cell: (info) => {
        return (
          <TableActions
            isActive={info.row.original.active}
            handleActive={() => handleSubmitActive(info.row.original._id, true)}
            handleDesactive={() => handleSubmitActive(info.row.original._id, false)}
            handleDelete={() => modalDeleteRef.current?.openModal(info.row.original._id)}
          />
        )
      },
    }),
  ]

  return (
    <Container>
      <CreateModal ref={modalCreate} reload={refetch} />
      <ModalDelete
        loading={loading}
        title='Deletar esse Estabelecimento?'
        ref={modalDeleteRef}
        onSubmitButton={handleSubmitDelete}
      />
      <Table
        title='Estabelecimento'
        onSearch={onSearch}
        isLoading={isLoading}
        columns={columns}
        onSort={onSortElement}
        onOpenRow={handleOpenRow}
        create={handleCreate}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
    </Container>
  )
}

export default EstablishmentHome
