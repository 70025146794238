import { useQuery } from '@tanstack/react-query'
import { api } from './api'

export type User = {
  name: string
  email: string
  password?: string
  photo?: string | File
  createdAt: string
}

type Users = {
  data: User[]
  page: number
  petPage: number
  totalPages: number
  total: number
}

async function fetchUser(
  page?: number,
  perPage?: number,
  search?: string,
  sort?: string,
): Promise<Users> {
  let sortName: any = 'createdAt'
  let order: string = 'desc'

  if (sort) {
    var keyNames = Object.keys(sort)
    sortName = keyNames[0]
    order = sort[sortName]
  }

  const res = await api.get('/users', {
    params: {
      page,
      perPage,
      search,
      sort: sortName,
      order: order === 'desc' ? -1 : 1,
    },
  })
  return res.data
}

function useUsers(
  page: number | undefined,
  perPage: number | undefined,
  search: string | undefined,
  sort?: string | undefined,
) {
  return useQuery(['users', page, perPage, search, sort], () => fetchUser(page, perPage, search, sort), {
    keepPreviousData: true,
    staleTime: 5000,
  })
}

export default useUsers
