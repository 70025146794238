import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom'
import Forgot from '../pages/Auth/Forgot'
import ForgotChangePassword from '../pages/Auth/ForgotChangePassword'
import Login from '../pages/Auth/Login'
import Register from '../pages/Auth/Register'

import NotFound from '../pages/NotFound'
import Profile from '../pages/Profile'
import AuthRoute from './AuthRoute'
import PrivateRoute from './PrivateRoute'
import PrivateRouteAdmin from './PrivateRouteAdmin'

/* Admin routes */
import Companies from '../pages/Dashboard/Companies'
import Home from '../pages/Dashboard/Home'
import Users from '../pages/Dashboard/Users'
import Configs from '../pages/Company/Configs'
import SalesAdmin from '../pages/Dashboard/Sales'
import TransactionsAdmin from '../pages/Dashboard/Transactions'

import DashCompany from '../pages/Dashboard/DashCompany'
import DashCompanyHome from '../pages/Dashboard/DashCompany/Home'
import DashCompanyTransaction from '../pages/Dashboard/DashCompany/Transactions'
import DashCompanyConfig from '../pages/Dashboard/DashCompany/Configs'
import DashCompanySales from '../pages/Dashboard/DashCompany/Sales'
import DashCompanyTaxes from '../pages/Dashboard/DashCompany/Taxes'

/* Company routes */
import SelectCompany from '../pages/Company/SelectCompany'
import CompanyHome from '../pages/Company/Home'
import CreateCompany from '../pages/Company/CreateCompany'
import Establishment from '../pages/Company/Establishment'
import BankingCompany from '../pages/Company/Banking'

import ClientHome from '../pages/Company/Clients'
import Sales from '../pages/Company/Sales'
import Transactions from '../pages/Company/Transactions'

/* Establishment */
import DashEstablishment from '../pages/Company/DashEstablishment'
import EstablishmentHome from '../pages/Company/DashEstablishment/Home'
import EstablishmentTransaction from '../pages/Company/DashEstablishment/Transactions'
import EstablishmentConfig from '../pages/Company/DashEstablishment/Configs'
import EstablishmentSales from '../pages/Company/DashEstablishment/Sales'
import EstablishmentTaxes from '../pages/Company/DashEstablishment/Taxes'
import EstablishmentBanking from '../pages/Company/DashEstablishment/Banking'
import SaleDetails from '../pages/Dashboard/Sales/SaleDetails'
import Invite from '../pages/Auth/Invite'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* Company */}
      <Route path='/' element={<PrivateRoute Component={CompanyHome} />} />
      <Route
        path='/create-company'
        element={<PrivateRoute blockCompany Component={CreateCompany} />}
      />
      <Route path='/establishments' element={<PrivateRoute Component={Establishment} />} />
      <Route path='/clients' element={<PrivateRoute Component={ClientHome} />} />
      <Route
        path='/transferAccounts'
        element={<PrivateRoute Component={BankingCompany} />}
      />
      <Route path='/sales' element={<PrivateRoute Component={Sales} />} />
      <Route path='/sales/:id' element={<PrivateRoute Component={SaleDetails} />} />
      <Route path='/transactions' element={<PrivateRoute Component={Transactions} />} />
      <Route path='/configs' element={<PrivateRoute Component={Configs} />} />
      <Route path='/profile' element={<PrivateRoute Component={Profile} />} />

      <Route path='/my-company' element={<PrivateRoute blockCompany Component={SelectCompany} />} />
      <Route
        path='/establishments/:id'
        element={<DashEstablishment Component={EstablishmentHome} />}
      />
      <Route
        path='/establishments/:id/sales'
        element={<DashEstablishment Component={EstablishmentSales} />}
      />
         <Route
        path='/establishments/:id/sales/:sale'
        element={<DashEstablishment Component={SaleDetails} />}
      />
      <Route
        path='/establishments/:id/transactions'
        element={<DashEstablishment Component={EstablishmentTransaction} />}
      />
      <Route
        path='/establishments/:id/configs'
        element={<DashEstablishment Component={EstablishmentConfig} />}
      />
      <Route
        path='/establishments/:id/taxes'
        element={<DashEstablishment Component={EstablishmentTaxes} />}
      />
      <Route
        path='/establishments/:id/transferAccounts'
        element={<DashEstablishment Component={EstablishmentBanking} />}
      />

      {/* Admin */}
      <Route path='/dashboard' element={<PrivateRouteAdmin Component={Home} />} />
      <Route path='/users' element={<PrivateRouteAdmin Component={Users} />} />
      <Route path='/companies' element={<PrivateRouteAdmin Component={Companies} />} />
      <Route path='/reports' element={<PrivateRouteAdmin Component={SalesAdmin} />} />
      <Route path='/earnings' element={<PrivateRouteAdmin Component={TransactionsAdmin} />} />
      <Route path='/reports/:id' element={<PrivateRouteAdmin Component={SaleDetails} />} />

      <Route
        path='/companies/:id'
        element={<DashCompany Component={DashCompanyHome} />}
      />
      <Route
        path='/companies/:id/sales'
        element={<DashCompany Component={DashCompanySales} />}
      />
 
      <Route
        path='/companies/:id/transactions'
        element={<DashCompany Component={DashCompanyTransaction} />}
      />
      <Route
        path='/companies/:id/configs'
        element={<DashCompany Component={DashCompanyConfig} />}
      />
      <Route
        path='/companies/:id/taxes'
        element={<DashCompany Component={DashCompanyTaxes} />}
      />

      {/* Auth */}
      <Route path='/invite/:id' element={<AuthRoute Component={Invite} />} />
      <Route path='/login' element={<AuthRoute Component={Login} />} />
      <Route path='/register' element={<AuthRoute Component={Register} />} />
      <Route path='/forgot' element={<AuthRoute Component={Forgot} />} />
      <Route
        path='/forgot-password/:token'
        element={<AuthRoute Component={ForgotChangePassword} />}
      />

      <Route path='*' element={<AuthRoute Component={NotFound} />} />
    </Switch>
  </BrowserRouter>
)

export default Routes
