import React from 'react'

import { Content } from './styles'

import { Form, Title } from '../../../components/commons'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import Input from '../../../components/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { CompanyCreateForm, companyCreateSchema } from '../../../validators/company.schema'
import TextArea from '../../../components/Input/TextArea'
import useToast from '../../../hooks/useToast'
import { api } from '../../../services/api'
import { masks } from '../../../utils/masks'

export interface CreateCompanyrops {}

const CreateCompany: React.FunctionComponent<CreateCompanyrops> = () => {
  const nav = useNavigate()

  const toast = useToast()

  const [loading, setLoading] = React.useState(false)

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CompanyCreateForm>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      type: 'individual',
      description: '',
      document: '',
    },
    resolver: yupResolver(companyCreateSchema),
  })

  const handleSubmitCreate = async (company: CompanyCreateForm) => {
    try {
      setLoading(true)
      company.document = masks.unmask(company.document)
      company.type = company?.document?.length > 12 ? 'company' : 'individual'

      company.phone = masks.unmask(company.phone)

      await api.post('/companies', company)

      toast.successToast('Empresa cadastrada com sucesso!')
      nav('/my-company')
    } catch (error: any) {
      if (error?.response?.data?.message === 'Document is in use in another company!.') {
        toast.errorToast('Documento já em uso !')
      } else {
        toast.errorToast('Erro ao cadastrar empresa !')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Content>
      <Title>Cadastrar uma empresa</Title>
      <Form onSubmit={handleSubmit(handleSubmitCreate)}>
        <Input
          placeholder='Digite o nome'
          error={errors?.name}
          type='text'
          control={control}
          label='Nome'
          name='name'
        />

        <Input
          placeholder='Digite seu email'
          error={errors?.email}
          control={control}
          label='E-mail'
          type='email'
          name='email'
        />

        <Input
          placeholder='Digite telefone'
          error={errors?.phone}
          control={control}
          label='Telefone'
          type='text'
          mask='cell'
          name='phone'
        />

        <Input
          placeholder='Digite CPF/CNPJ da empresa'
          error={errors?.document}
          control={control}
          label='CPF/CNPJ'
          type='text'
          name='document'
          mask='document'
        />

        <TextArea
          placeholder='Descrição'
          error={errors?.description}
          control={control}
          label='Descrição'
          name='description'
        />

        <Button
          onClick={() => nav('/my-company')}
          label='Voltar'
          variantType='label'
          type='submit'
          fullWidth
          color='secondary'
        />
        <Button
          label='Registrar'
          loading={loading}
          type='submit'
          variantType='block'
          color='secondary'
        />
      </Form>
    </Content>
  )
}

export default CreateCompany
