import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import * as jose from 'jose'

import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Container, ContentCenter, Form, Title } from '../../../components/commons'
import { useNavigate, useParams } from 'react-router-dom'
import useToast from '../../../hooks/useToast'
import { api } from '../../../services/api'
import { UserRegisterForm, registerSchema } from '../../../validators/auth.schemas'

export interface InviteProps {}

const Invite: React.FunctionComponent<InviteProps> = () => {
  const navigate = useNavigate()

  const toast = useToast()
  const { id } = useParams()

  const [decodedJWT, setDecodedJWT] = React.useState<{ email: string; name: string }>()

  const [loading, setLoading] = React.useState<boolean>(false)

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UserRegisterForm>({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(registerSchema),
  })

  const onSubmit = async (formData: UserRegisterForm) => {
    try {
      setLoading(true)

      await api.post('/signup', {
        email: formData.email,
        name: formData.name,
        password: formData.password,
        token: id,
      })

      toast.successToast('Cadastro completado com sucesso!')
      return navigate('/login')
    } catch (error) {
      toast.errorToast('Erro ao completar cadastro, verifique os dados e tente !')
    } finally {
      setLoading(false)
    }
  }

  const verifyJWT = async () => {
    try {
      if(!id) return 

      const protectedHeader: any = jose.decodeJwt(id)

      setValue('email', protectedHeader.email)
      setValue('name', protectedHeader.name)

      setDecodedJWT(protectedHeader)
    } catch (error) {
      navigate('/login')
    }
  }

  React.useEffect(() => {
    if (id) {
      verifyJWT()
    }
  }, [id])

  return (
    <Container>
      <Title>Completar Cadastro</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder='Digite seu nome'
          error={errors?.name}
          control={control}
          label='Nome'
          type='text'
          name='name'
        />
        <Input
          placeholder='Digite seu email'
          error={errors?.email}
          control={control}
          disabled
          label='E-mail'
          type='email'
          name='email'
        />

        <Input
          placeholder='Digite sua senha'
          error={errors?.password}
          control={control}
          label='Senha'
          type='password'
          name='password'
        />
        <Input
          placeholder='Confirme sua senha'
          error={errors?.confirmPassword}
          control={control}
          label='Confirme sua senha'
          type='password'
          name='confirmPassword'
        />

        <Button
          label='Completar'
          loading={loading}
          type='submit'
          variantType='block'
          color='secondary'
        />
        <ContentCenter>
          <Button
            onClick={() => navigate('/login')}
            label='Voltar'
            variantType='label'
            color='primary'
          />
        </ContentCenter>
      </Form>
    </Container>
  )
}

export default Invite
