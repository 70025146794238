/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { api } from '../../../services/api'
import SelectInput from '../../Input/SelectInput'
import { useParams } from 'react-router-dom'

export interface EstablishmentProps {
  name: string
  control: any
}

const Establishment: React.FunctionComponent<EstablishmentProps> = ({ control, name }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])

  const { id } = useParams()

  const [search, setSearch] = React.useState('')

  const handleGetQuery = async () => {
    try {
      setIsLoading(true)

      const { data: response } = await api.get('/establishments', {
        params: {
          perPage: 25,
          search,
          company: id
        },
      })

      setOptions(
        response.data.map((el: any) => ({
          label: el.name,
          value: el._id,
        })),
      )
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    handleGetQuery()
  }, [search])

  return (
    <>
      <SelectInput isMulti onInputChange={(text) => setSearch(text) } isSearchable label='Estabelecimentos' name={name} control={control} options={options} />
    </>
  )
}

export default Establishment
