import React from 'react'

import { Container } from './styles'
import { api } from '../../../../services/api'
import { useParams } from 'react-router-dom'
import useToast from '../../../../hooks/useToast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Form, Grid, GridItem, LabelSection } from '../../../../components/commons'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { masks } from '../../../../utils/masks'
import { format } from 'date-fns'
import { ContentFooter, GridCard } from '../../../Company/Configs/styles'
import { Company } from '../../../../services/companies.service'
import { companyEditSchema } from '../../../../validators/company.schema'

export interface ConfigsProps {
  company: Company
  reflesh: () => void
}

const Configs: React.FunctionComponent<ConfigsProps> = ({ company, reflesh }) => {
  const [loading, setLoading] = React.useState(false)

  const { id } = useParams()

  const toast = useToast()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Company>({
    defaultValues: {
      ...company,
      document: company && masks.document(company.document),
      phone: company && masks.cell(company.phone),
      createdAt:
        company?.createdAt && format(new Date(company?.createdAt), 'dd/MM/yyyy HH:mm'),
    },
    resolver: yupResolver(companyEditSchema),
  })

  const handleSubmitForm = async (formData: Company) => {
    try {
      setLoading(true)

      await api.put(`/companies/${id}`, {
        name: formData.name,
        phone: masks.unmask(formData.phone),
      })

      reflesh()
      toast.successToast('Empresa atualizada com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao editar Empresa !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <GridCard flexDirection='column' gap={25}>
          <LabelSection>Dados cadastrais</LabelSection>
          <Grid flexDirection='row' gap={25}>
            <GridItem>
              <Input
                placeholder='Digite seu nome'
                error={errors?.name}
                control={control}
                label='Nome'
                type='text'
                name='name'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite seu Email'
                error={errors?.email}
                control={control}
                disabled
                label='Email'
                type='email'
                name='email'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite seu Telefone'
                error={errors?.phone}
                control={control}
                label='Telefone'
                type='text'
                mask='cell'
                name='phone'
              />
            </GridItem>
          </Grid>
          <Grid flexDirection='row' gap={25}>
            <GridItem>
              <Input
                placeholder='Digite seu Documento'
                error={errors?.document}
                control={control}
                label='Documento'
                disabled
                type='text'
                mask='document'
                name='document'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite seu Telefone'
                error={errors?.createdAt}
                control={control}
                label='Data de Criação'
                disabled
                type='text'
                name='createdAt'
              />
            </GridItem>
          </Grid>
          <ContentFooter>
            <Button
              color='secondary'
              loading={loading}
              type='submit'
              variantType='block'
              label='Salvar'
            />
          </ContentFooter>
        </GridCard>
      </Form>
    </Container>
  )
}

export default Configs
