
import React from 'react'
import { Container } from '../../../components/commons'
import CompanyData from './CompanyData'
import IntegrationData from './IntegrationData'
import TaxesData from './TaxesData'
import { useEstablishmentStore } from '../../../store/establishment'

export interface ConfigsProps {}

const Configs: React.FunctionComponent<ConfigsProps> = () => {
  const establishment = useEstablishmentStore.getState().establishment

  return (
    <Container>
      <CompanyData />
      <TaxesData />
      {
        !establishment && (
          <IntegrationData />
        )
      }
    </Container>
  )
}

export default Configs
