import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Content, Error, Label } from '../styles'
import { IoClose } from 'react-icons/io5'
import { ImageWrapper, RemoveButton } from './styles'
import defaultImage from '../../../assets/images/default-image.png'
import { theme } from '../../../styles/theme'

export interface ImageIFileProps {
  onBlur: () => void
  onChange: (file: any) => void
  error?: boolean
  value: string
  name: string
  width: number
  borderRadius: number
  height: number
}

export interface ImageInputProps {
  control: any
  label?: string
  width?: number
  height?: number
  error?: FieldError
  borderRadius?: number
  name: string
}

const ImageFile: React.FunctionComponent<ImageIFileProps> = ({
  width,
  height,
  value,
  borderRadius,
  name,
  onChange,
}) => {
  const [imageData, setImageData] = React.useState<string | undefined>(value)

  const inputRef = React.useRef<any>(null)

  const remove = (e: Event) => {
    if (inputRef?.current && inputRef?.current.value) {
      inputRef.current.value = ''
    }
    e.preventDefault()
    setImageData(undefined)
  }

  const handleChange = (e: any) => {
    if (e.target.files.length) {
      e.persist()

      let image = e.target.files[0]
      onChange(image)

      setImageData(URL.createObjectURL(image))
    }
  }

  return (
    <Content htmlFor={name} style={{ width: 'fit-content' }}>
      {imageData && (
        <RemoveButton onClick={remove}>
          <IoClose color={theme.colors.border} width={10} height={10} />
        </RemoveButton>
      )}
      <ImageWrapper
        borderRadius={borderRadius}
        onClick={() => inputRef.current && inputRef.current.click()}
        width={width}
        height={height}
        src={imageData || defaultImage}
      >
        <input
          style={{ display: 'none' }}
          id={name}
          ref={inputRef}
          name={name}
          type='file'
          onChange={handleChange}
          accept={'image/png, image/jpeg'}
        />
      </ImageWrapper>
    </Content>
  )
}

const ImageInput: React.FunctionComponent<ImageInputProps> = ({
  width = 150,
  height = 150,
  borderRadius = 100,
  control,
  label,
  error,
  name,
}) => {
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Content>
            {label && <Label>{label}</Label>}
            <ImageFile
              width={width}
              borderRadius={borderRadius}
              height={height}
              name={name}
              value={value}
              error={!!error}
              onBlur={onBlur}
              onChange={onChange}
            />
            {error && <Error>{error.message}</Error>}
          </Content>
        )
      }}
    />
  )
}

export default ImageInput
