import React, { useRef, useState } from 'react'

import { Content } from './styles'
import Modal from '../Modal'
import Button from '../../Button'

export interface ModalHandle {
  openModal: (_id: string) => void
  closeModal: () => void
}

export interface ModalProps {
  title?: string
  loading: boolean
  onSubmitButton: (_id: string) => void
}

type ModalRef = React.ElementRef<typeof Modal>

const ModalDelete: React.ForwardRefRenderFunction<ModalHandle, ModalProps> = (
  { title, onSubmitButton, loading },
  ref,
) => {
  const modalRef = useRef<ModalRef>(null)

  const [deleteId, setDeleteId] = useState<string>('')

  React.useImperativeHandle(ref, () => ({
    openModal(_id: string) {
      setDeleteId(_id)
      modalRef.current?.openModal()
    },
    closeModal() {
      modalRef.current?.closeModal()
    },
  }))

  return (
    <Modal ref={modalRef} maxWidth={700} title={title}>
      <Content>
        <Button color='success' onClick={() => modalRef.current?.closeModal()} variantType='block' label='Cancelar' />
        <Button
          color='primary'
          variantType='block'
          loading={loading}
          label='Deletar'
          onClick={() => onSubmitButton(deleteId)}
        />
      </Content>
    </Modal>
  )
}

export default React.forwardRef(ModalDelete)
