import React from 'react'
import { IoEye, IoPauseCircleSharp, IoPlayCircle, IoTrash } from 'react-icons/io5'
import { theme } from '../../../styles/theme'
import { Actions } from '../../commons'

export interface TableActionsProps {
  handleDelete?: () => void
  handleActive?: () => void
  handleDesactive?: () => void
  onView?: () => void
  isActive?: boolean
}

const TableActions: React.FunctionComponent<TableActionsProps> = ({
  handleDelete,
  handleActive,
  handleDesactive,
  onView,
  isActive,
}) => {
  return (
    <Actions>
      {typeof isActive !== 'undefined' && (
        <>
          {isActive ? (
            <span onClick={() => handleDesactive && handleDesactive()}>
              <IoPauseCircleSharp color={theme.colors.secondary} size={20} />
            </span>
          ) : (
            <span onClick={() => handleActive && handleActive()}>
              <IoPlayCircle color={theme.colors.secondary} size={20} />
            </span>
          )}
        </>
      )}
      {onView && (
        <span onClick={() => onView()}>
          <IoEye color={theme.colors.secondary} size={20} />
        </span>
      )}

      {handleDelete && (
        <span onClick={() => handleDelete()}>
          <IoTrash color={theme.colors.secondary} size={20} />
        </span>
      )}
    </Actions>
  )
}

export default TableActions
