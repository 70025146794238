import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../components/Button'
import { Container, ContentCenter, Form } from '../../components/commons'
import Input from '../../components/Input'
import ImageInput from '../../components/Input/ImageInput'
import useToast from '../../hooks/useToast'
import { api } from '../../services/api'
import { User } from '../../services/users.service'
import { useUserStore } from '../../store/user'
import { updateUserSchema } from '../../validators/auth.schemas'

export interface ProfileProps {}

const Profile: React.FunctionComponent<ProfileProps> = () => {
  const { user, setUser } = useUserStore((state) => state)
  const [loading, setLoading] = React.useState<boolean>(false)

  const toast = useToast()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    defaultValues: user,
    resolver: yupResolver(updateUserSchema),
  })

  const handleSubmitLogin = async (formData: User) => {
    try {
      setLoading(true)

      if(formData.password === '') {
        formData.password = undefined
      }

      const { data: response } = await api.put('/users/me', {
        name: formData.name,
        password: formData.password,
      })

      setUser(response)

      if (typeof formData.photo === 'object') {
        let formDataUpload: FormData = new FormData()
        formDataUpload.append('photo', formData.photo)
        try {
          await api.put('/users/me/upload', formDataUpload)
        } catch (error) {
          toast.errorToast('Erro ao enviar a foto perfil !')
        }
      }

      toast.successToast('Perfil Atualizado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao editar perfil !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container style={{ maxWidth: 400, margin: 'auto' }}>
      <Form onSubmit={handleSubmit(handleSubmitLogin)}>
        <ContentCenter>
          <ImageInput borderRadius={100} error={errors?.name} control={control} name='photo' />
        </ContentCenter>
        <Input
          placeholder='Digite seu nome'
          error={errors?.name}
          control={control}
          label='Nome'
          type='text'
          name='name'
        />

        <Input
          placeholder='Senha'
          error={errors?.password}
          control={control}
          label='Senha'
          type='password'
          name='password'
        />

        <Button
          label='Editar'
          loading={loading}
          type='submit'
          fullWidth
          variantType='block'
          color='secondary'
        />
      </Form>
    </Container>
  )
}

export default Profile
