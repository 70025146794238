import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React from 'react'
import { Container } from '../commons'
import {
  ButtonCreate,
  Content,
  Empty,
  HeaderMobile,
  HeaderTable,
  HeaderTableRow,
  LeftContent,
  LoaderComponent,
  SortContent,
  TableGrid,
  Tbody,
  Td,
  Th,
  Thead,
  TitleTable,
  Tr,
} from './styles'
import SearchInput from '../Input/SearchInput'
import { IoAddCircle, IoCaretDown, IoCaretUp } from 'react-icons/io5'

import { theme } from '../../styles/theme'

export interface TableProps {
  columns: any[]
  isLoading: boolean
  data?: any[]
  filters?: React.ReactElement
  title?: string
  onSort?: (name: string, sort: string) => void
  onOpenRow?: (_id: string) => void
  onSearch?: (value: string) => void
  create?: () => void
}

const SortComponent = ({
  sortDefault = 'desc',
  name,
  onSort,
}: {
  sortDefault?: string
  name: string
  onSort?: (name: string, sort: string) => void
}) => {
  const [actualSort, setActualSort] = React.useState(sortDefault)

  const onChange = () => {
    let change = actualSort === 'asc' ? 'desc' : 'asc'
    setActualSort(change)
    if (onSort) {
      onSort(name, change)
    }
  }
  return (
    <SortContent onClick={() => onChange()}>
      <IoCaretUp size={15} color={actualSort === 'asc' ? theme.colors.text : '#808080'} />
      <IoCaretDown size={15} color={actualSort !== 'asc' ? theme.colors.text : '#808080'} />
    </SortContent>
  )
}

const Table: React.FunctionComponent<TableProps> = ({
  isLoading,
  columns,
  title,
  data = [],
  filters,
  onSort,
  onSearch,
  create,
  onOpenRow,
}) => {
  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  let headersGroup: any = table.getHeaderGroups()[0]

  return (
    <Container>
      <Content>
        <HeaderTable>
          <TitleTable>{title || ''}</TitleTable>
          <LeftContent>
            {onSearch && (
              <SearchInput isLoading={isLoading} onSearch={onSearch} placeholder='Buscar' />
            )}
            {create && (
              <ButtonCreate onClick={create}>
                <IoAddCircle size={45} color={theme.colors.text} />
              </ButtonCreate>
            )}
          </LeftContent>
        </HeaderTable>

        {isLoading && (
          <div className='loading-overlay'>
            <Empty>
              <LoaderComponent />
            </Empty>
          </div>
        )}
        <TableGrid>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th key={header.id}>
                      <HeaderTableRow>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}

                        {header.column.columnDef.enableSorting && (
                          <SortComponent name={header.id} onSort={onSort} />
                        )}
                      </HeaderTableRow>
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.length > 0 &&
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell, indexCell) => {
                    const active =
                      typeof cell.row.original?.active !== 'undefined'
                        ? cell.row.original?.active
                        : true

                    let headerName = flexRender(
                      headersGroup.headers[indexCell].column.columnDef.header,
                      headersGroup.headers[indexCell].getContext(),
                    )

                    return (
                      <Td
                        key={cell.id}
                        onClick={() => (onOpenRow ? onOpenRow(cell.row.original._id) : {})}
                        onOpenRow={!!onOpenRow}
                        active={active}
                      >
                        <HeaderMobile>{headerName}:</HeaderMobile>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    )
                  })}
                </Tr>
              ))}
          </Tbody>
        </TableGrid>

        {!isLoading && table.getRowModel().rows.length <= 0 && (
          <Empty>Nenhum dado encontrado</Empty>
        )}
      </Content>
    </Container>
  )
}

export default Table
