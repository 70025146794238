import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { StatusColorName, TypeEarning } from '../../../../components/commons'
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Table/Pagination'
import useTransactions, { Transaction } from '../../../../services/transactions.service'
import { masks } from '../../../../utils/masks'
import { Container } from '../Sales/styles'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import Filters from '../../../../components/Filters'

export interface TransactionsProps {}

const Transactions: React.FunctionComponent<TransactionsProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')
  const [sort, setSort] = React.useState<any>()
  const [filters, setFilters] = React.useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  })
  const { isLoading, data, refetch, isFetching } = useTransactions(
    page,
    18,
    search,
    undefined,
    false,
    sort,
    filters,
  )

  const columnHelper = createColumnHelper<Transaction>()

  const pageChangeHandler = (page: number) => setPage(page)

  const onSortElement = (name: string, sort: string) => {
    setSort({ [name]: sort })
  }

  const handleChangeFilter = async (filter: any) => {
    setFilters({ ...filter })
  }

  const columns = [
    columnHelper.accessor('_id', {
      header: () => 'Id',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('value', {
      header: () => 'Valor Total',
      enableSorting: true,
      cell: (info) => info.renderValue() && masks.currencyAllPlatforms(info.renderValue()),
    }),
    columnHelper.accessor('value', {
      header: () => 'Ganhos',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() && info.row.original.type !== 'deposit' ? (
          <>{masks.currencyAllPlatforms((info.renderValue() || 0) - info.row.original.taxValue)}</>
        ) : (
          masks.currencyAllPlatforms(0)
        ),
    }),
    columnHelper.accessor('tax', {
      header: () => 'Taxa %',
      enableSorting: true,
      cell: (info) => info.renderValue() && <>{info.row.original.tax} %</>,
    }),
    columnHelper.accessor('type', {
      header: () => 'Tipo',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() && (
          <TypeEarning type={info.row.original.payment ? 'deposit' : info.renderValue() || ''} />
        ),
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Data',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() !== null &&
        format(new Date(info.renderValue() || ''), 'dd/MM/yyyy HH:mm'),
    }),
    columnHelper.accessor('status', {
      header: () => 'Status',
      enableSorting: true,
      cell: (info) => <StatusColorName status={info.renderValue() || ''} />,
    }),
  ]

  return (
    <Container>
      <Filters
        filters={filters}
        statusArray={[
          {
            label: 'Pendente',
            value: 'pending',
          },
          {
            label: 'Cancelado',
            value: 'canceled',
          },
          {
            label: 'Finalizado',
            value: 'finished',
          },
        ]}
        tipoArray={[
          {
            label: 'Entrada',
            value: 'deposit',
          },
          {
            label: 'Saida',
            value: 'withdraw',
          },
          {
            label: 'Antecipação',
            value: 'antecipate',
          },
        ]}
        client
        establishment
        rangeDate
        onChange={handleChangeFilter}
      />
      <Table
        title='Transações'
        onSort={onSortElement}
        isLoading={isLoading || isFetching}
        columns={columns}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
    </Container>
  )
}

export default Transactions
