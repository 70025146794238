import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

export const Content = styled.div<{ full: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: ${({ full }) => (full ? '100%' : '50%')};
  word-break: break-word;
  
  ${down('md')} {
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const TitleElement = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const TitleCard = styled.label`
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e9e9e9;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
`

export const Label = styled.label<{ link?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  ${({ link }) => link && css`cursor: pointer;`};
  color: ${({ theme }) => theme.colors.primary};
`
