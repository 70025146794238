import styled, { css } from 'styled-components'
import ripple from '../../../styles/animations/ripple'

export const Container = styled.div`
  display: flex;
`
export const CardCompany = {
  Content: styled.div<{ companySelected?: boolean }>`
    ${ripple}
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 15px;
    padding: 5px;
    width: fit-content;
  `,
  Row: styled.div`
    display: flex;
    gap: 15px;
    flex-direction: row;
  `,
  Name: styled.div<{ companySelected?: boolean }>`
    font-size: 18px;
    font-weight: bold;
  `,
  Document: styled.div<{ companySelected?: boolean }>`
    font-size: 14px;
  `,
}
