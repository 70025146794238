/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { masks } from '../../../utils/masks'
import { Content, Error, FieldArea, Label } from './styles'

export interface TextAreaProps {
  placeholder?: string
  control: any
  label?: string
  error?: FieldError
  name: string
  mask?: any
  footerText?: string
}

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  control,
  placeholder,
  label,
  error,
  name,
  mask,
}) => {
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        const handleOnChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          let value = e.target.value
          if (mask) {
            value = masks[mask](String(value))
          }
          onChange(value)
        }
        return (
          <Content>
            {label && <Label>{label}</Label>}
            <FieldArea
              value={value}
              error={!!error}
              onBlur={onBlur}
              onChange={handleOnChange}
              placeholder={placeholder}
            />
            {error && <Error>{error.message}</Error>}
          </Content>
        )
      }}
    />
  )
}

export default TextArea
