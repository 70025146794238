import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../../components/Button'
import { Container, Form, Grid, GridItem, LabelSection } from '../../../../components/commons'
import Input from '../../../../components/Input'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import { useCompanyStore } from '../../../../store/company'
import { useUserStore } from '../../../../store/user'
import { TaxesDto, taxesEditSchema } from '../../../../validators/company.schema'
import { GridCard, ContentFooter } from '../styles'
import { useEstablishmentStore } from '../../../../store/establishment'

export interface TaxesDataProps {}

const TaxesData: React.FunctionComponent<TaxesDataProps> = () => {
  const [loading, setLoading] = React.useState(false)

  const establishment = useEstablishmentStore.getState().establishment

  const company = useCompanyStore.getState().company || establishment

  const user = useUserStore((state) => state.user)

  const toast = useToast()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TaxesDto>({
    defaultValues: company && company.taxes,
    resolver: yupResolver(taxesEditSchema),
  })

  const handleSubmitTaxes = async (formData: TaxesDto) => {
    try {
      setLoading(true)

      const { data: response } = await api.put('/companies', {
        taxes: formData,
      })

      useCompanyStore.getState().setCompany(response)
      toast.successToast('Empresa atualizada com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao editar empresa !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(handleSubmitTaxes)}>
        <GridCard flexDirection='column' gap={25}>
          <LabelSection>Taxas</LabelSection>
          <Grid flexDirection='row' gap={5}>
            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.credit}
                disabled
                control={control}
                label='Cartão de Crédito (%)'
                type='text'
                name='credit'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.debit}
                control={control}
                disabled
                label='Debito - Visa e MasterCard (%)'
                type='text'
                name='debit'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.debit2}
                control={control}
                disabled
                label='Debito outras bandeiras (%)'
                type='text'
                name='debit2'
              />
            </GridItem>
            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.pix}
                control={control}
                label='Pix (%)'
                disabled
                type='text'
                name='pix'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite a taxa'
                error={errors?.boleto}
                control={control}
                label='Boleto (%)'
                disabled
                type='text'
                name='boleto'
              />
            </GridItem>
          </Grid>

          {user?.role === 'admin' && (
            <ContentFooter>
              <Button
                color='secondary'
                loading={loading}
                type='submit'
                variantType='block'
                label='Salvar'
              />
            </ContentFooter>
          )}
        </GridCard>
      </Form>
    </Container>
  )
}

export default TaxesData
