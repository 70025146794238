import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../../components/Button'
import { Container, Form } from '../../../../components/commons'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modals/Modal'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import { masks } from '../../../../utils/masks'
import {
  EstablishmentCreateForm,
  establishmentCreateSchema,
} from '../../../../validators/establishment.schema'

export interface CreatePlanProps {
  reload: () => void
}

export interface ModalHandle {
  openFormModal: (initialData?: EstablishmentCreateForm) => void
}

type ModalRef = React.ElementRef<typeof Modal>

const CreatePlan: React.ForwardRefRenderFunction<ModalHandle, CreatePlanProps> = (
  { reload },
  ref,
) => {
  const [loading, setLoading] = useState(false)

  const [initialData, setInitialData] = useState<EstablishmentCreateForm>({
    name: '',
    email: '',
    type: 'individual',
    phone: '',
    document: '',
  })

  const toast = useToast()

  const modalRef = useRef<ModalRef>(null)

  const handleOpenModalCreate = () => {
    modalRef.current?.openModal()
  }

  const {
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EstablishmentCreateForm>({
    defaultValues: {
      name: initialData.name,
      email: initialData.email,
      type: initialData.type,
      phone: initialData.phone,
      document: initialData.document,
    },
    resolver: yupResolver(establishmentCreateSchema),
  })



  const handleSubmitLogin = async (formData: EstablishmentCreateForm) => {
    try {
      setLoading(true)

      formData.document = masks.unmask(formData.document)
      formData.type = formData?.document?.length > 12 ? 'company' : 'individual'


      if (initialData?._id) {
        await api.put(`/establishments${initialData?._id}`, formData)
      } else {
        await api.post('/establishments', formData)
      }

      modalRef.current?.closeModal()
      reload()
      reset()
      toast.successToast('Estabelecimento criado com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao criar estabelecimento !')
    } finally {
      setLoading(false)
    }
  }

  React.useImperativeHandle(ref, () => ({
    openFormModal(initialData) {
      if (initialData) {
        setInitialData(initialData)
        setValue('name', initialData.name)
        setValue('email', initialData.email)
        setValue('phone', initialData.phone)
        setValue('document', masks.document(initialData.document))
      } else {
        setInitialData({
          name: '',
          email: '',
          phone: '',
          document: '',
        })
        reset()
      }
      handleOpenModalCreate()
    },
  }))

  return (
    <>
      <Modal title='Adicionar Estabelecimento' ref={modalRef}>
        <Container>
          <Form onSubmit={handleSubmit(handleSubmitLogin)}>
            <Input
              type='text'
              placeholder='Nome'
              label='Nome'
              error={errors?.name}
              control={control}
              name='name'
            />

            <Input
              type='email'
              placeholder='E-mail'
              label='E-mail'
              error={errors?.email}
              control={control}
              name='email'
            />
            <Input
              type='text'
              mask='cell'
              placeholder='Telefone'
              label='Telefone'
              error={errors?.phone}
              control={control}
              name='phone'
            />

            <Input
              type='text'
              mask='document'
              label='CPF/CNPJ'
              placeholder='CPF/CNPJ'
              error={errors?.document}
              control={control}
              name='document'
            />

            <Button
              label='Salvar'
              loading={loading}
              type='submit'
              variantType='block'
              color='secondary'
            />
          </Form>
        </Container>
      </Modal>
    </>
  )
}

export default React.forwardRef(CreatePlan)
