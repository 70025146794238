/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Content, Copy, Error, Field, Label } from './styles'
import { masks } from '../../utils/masks'
import {
  IoCopyOutline
} from 'react-icons/io5'
import useToast from '../../hooks/useToast'
import { theme } from '../../styles/theme'
export interface InputProps {
  placeholder?: string
  control: any
  label?: string
  type: string
  error?: FieldError
  disabled?: boolean
  copy?: boolean
  name: string
  mask?: any
  footerText?: string
}

const Input: React.FunctionComponent<InputProps> = ({
  control,
  placeholder,
  label,
  disabled,
  type,
  error,
  name,
  copy,
  mask,
}) => {

  const toast = useToast()

  const handleClickCopy = (copy: string) => {
    navigator.clipboard.writeText(copy)
    toast.successToast('Chave copiada!')
  }

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
          let value = e.target.value
          if (mask) {
            value = masks[mask](masks.unmask(String(value)))
          }
          onChange(value)
        }
        return (
          <Content>
            {label && (
              <Label>
                {label} {copy && <Copy onClick={() => handleClickCopy(value)}><IoCopyOutline color={theme.colors.secondary} /></Copy>}
              </Label>
            )}
            <Field
              autoComplete="none"
              role="presentation"
              disabled={disabled}
              value={value}
              error={!!error}
              onBlur={onBlur}
              onChange={handleOnChange}
              placeholder={placeholder}
              type={type}
            />
            {error && <Error>{error.message}</Error>}
          </Content>
        )
      }}
    />
  )
}

export default Input
