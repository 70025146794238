import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { Container } from '../../../components/commons'
import Table from '../../../components/Table'
import Pagination from '../../../components/Table/Pagination'
import useClient, { Client } from '../../../services/client.service'
import { masks } from '../../../utils/masks'
import { format } from 'date-fns'
import TableActions from '../../../components/Table/TableActions'
import WithdrawModal from './WithdrawModal'

export interface ClientHomeProps {}

type WithdrawModalRef = React.ElementRef<typeof WithdrawModal>


const ClientHome: React.FunctionComponent<ClientHomeProps> = () => {
  const [page, setPage] = React.useState<number>(1)
  const [search, setSearch] = React.useState('')
  const [sort, setSort] = React.useState<any>()

  const { isLoading, data } = useClient(page, 18, search, sort)

  const modalWithdraw = React.useRef<WithdrawModalRef>(null)

  const onSearch = (value: string) => {
    setSearch(value)
  }

  const onSortElement = (name: string, sort: string) => {
    setSort({ [name]: sort })
  }

  const columnHelper = createColumnHelper<Client>()

  const pageChangeHandler = (page: number) => setPage(page)
  
  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Nome',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('email', {
      header: () => 'Email',
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('phone', {
      header: () => 'Telefone',
      cell: (info) => {
        const phone = info.renderValue()
        return phone ? `${masks.cell(String(phone?.ddd) + String(phone?.number))}` : ''
      },
    }),
    columnHelper.accessor('document', {
      header: () => 'CPF/CNPJ',
      cell: (info) => info.renderValue() && masks.document(info.renderValue()),
    }),
    columnHelper.accessor('createdAt', {
      header: () => 'Data',
      enableSorting: true,
      cell: (info) =>
        info.renderValue() !== null &&
        format(new Date(info.renderValue() || ''), 'dd/MM/yyyy HH:mm'),
    }),
    columnHelper.accessor('_id', {
      header: () => 'Ações',
      cell: (info) => {
        return (
          <TableActions
            onView={() => modalWithdraw?.current?.openFormModal(info.row.original)}
          />
        )
      },
    }),
  ]

  return (
    <Container>
      <Table
        title='Clientes'
        onSearch={onSearch}
        onSort={onSortElement}
        isLoading={isLoading}
        columns={columns}
        data={data?.data}
      />
      <Pagination
        totalPages={data?.totalPages}
        page={page}
        perPage={18}
        pageChangeHandler={pageChangeHandler}
      />
      <WithdrawModal ref={modalWithdraw} />
    </Container>
  )
}

export default ClientHome
