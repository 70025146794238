import React from 'react'

import { Content, Header, Label, Row, Title, TitleCard, TitleElement } from './styles'
import { Card, Container, Grid, GridItem, StatusColorName } from '../../../../components/commons'
import { api } from '../../../../services/api'
import { useParams } from 'react-router-dom'
import { Sale } from '../../../../services/sales.service'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { masks } from '../../../../utils/masks'
import { typeNames } from '../../../../utils/namesTranslate'
import { useCompanyStore } from '../../../../store/company'
import { useEstablishmentStore } from '../../../../store/establishment'

export interface SaleDetailsProps {}

export const ItemElement = ({
  title,
  value = '',
  full,
  link,
}: {
  link?: boolean
  full?: boolean
  title: string
  value?: string
}) => (
  <Content full={!!full}>
    <TitleElement>{title}</TitleElement>
    <Label link={!!link} onClick={() => (link ? window.open(value, '_blank') : {})}>
      {value}
    </Label>
  </Content>
)
const SaleDetails: React.FunctionComponent<SaleDetailsProps> = () => {
  const [loading, setLoading] = React.useState(false)
  const [saleDetails, setSaleDetails] = React.useState<Sale>()
  const company = useCompanyStore((state) => state.company)
  const establishment = useEstablishmentStore((state) => state.establishment)

  const { id, sale } = useParams()

  const handleGetTransaction = async () => {
    setLoading(true)

    const { data: response } = await api.get(
      establishment
        ? `/establishments/${establishment._id}/payment/${sale || id}`
        : company
        ? `/companies/payment/${sale || id}`
        : `/report/sales/${sale || id}`,
    )

    let salesData = response

    if (salesData.refundedValue > 0 && salesData.refundedValue === salesData.value) {
      salesData.status = 'refunded'
    } else if (salesData.refundedValue > 0 && salesData.refundedValue < salesData.value) {
      salesData.status = 'partial-refunded'
    }

    setSaleDetails(salesData)
    setLoading(false)
  }

  React.useEffect(() => {
    handleGetTransaction()
  }, [])

  if (!saleDetails) return <></>

  return (
    <Container
      style={{
        marginTop: sale ? 25 : 0,
      }}
    >
      <Header>
        <Title>Detalhe da Venda</Title>
        <Label>
          {format(new Date(saleDetails.createdAt), 'dd MMMM yyyy HH:mm', {
            locale: ptBR,
          })}
        </Label>

        <StatusColorName
          status={saleDetails.status}
          value={saleDetails.value}
          refundedValue={saleDetails.refundedValue}
        />
      </Header>

      <Grid flexDirection='column' gap={25}>
        <Grid flexDirection='row' gap={25}>
          <GridItem>
            <Card>
              <TitleCard>Geral</TitleCard>

              <Row>
                <ItemElement
                  title='Valor da Venda:'
                  value={masks.currencyAllPlatforms(saleDetails.value)}
                />
                <ItemElement
                  title='Valor Liquido:'
                  value={masks.currencyAllPlatforms(
                    saleDetails.total - (saleDetails.transaction?.taxValue || 0),
                  )}
                />
              </Row>
              <Row>
                {(saleDetails.status === 'partial-refunded' ||
                  saleDetails.status === 'refunded') && (
                  <ItemElement
                    title='Valor reembolso:'
                    value={masks.currencyAllPlatforms(saleDetails.refundedValue)}
                  />
                )}
                <ItemElement
                  title='Total de Taxa:'
                  value={masks.currencyAllPlatforms(saleDetails.transaction?.taxValue || 0)}
                />
              </Row>

              <ItemElement
                title='Data do Pagamento'
                value={format(new Date(saleDetails.createdAt), 'dd/MM/yyyy')}
              />
              <ItemElement title='Tipo do Pagamento' value={typeNames(saleDetails.type)} />
              <ItemElement title='Id do pagamento' value={saleDetails._id} />
            </Card>
          </GridItem>
          <GridItem>
            <Card>
              <TitleCard>Dados da Empresa / Estabelecimento / Cliente</TitleCard>

              <Row>
                <ItemElement title='Nome da empresa' value={saleDetails.company?.name || ''} />
                <ItemElement
                  title='Documento  da empresa'
                  value={
                    saleDetails.company?.document && masks.document(saleDetails.company?.document)
                  }
                />
              </Row>
              <Row>
                <ItemElement
                  title='Nome do estabeleciemento'
                  value={saleDetails.establishment?.name || ''}
                />
                <ItemElement
                  title='Documento do estabeleciemento'
                  value={
                    saleDetails.establishment?.document &&
                    masks.document(saleDetails.establishment?.document)
                  }
                />
              </Row>
              <Row>
                <ItemElement title='Nome Cliente' value={saleDetails.client?.name} />
                <ItemElement
                  title='Documento Cliente '
                  value={masks.document(saleDetails.client?.document)}
                />
              </Row>
              <Row>
                <ItemElement title='Email Cliente ' value={saleDetails.client?.email} />
                <ItemElement
                  title='Telefone Cliente'
                  value={
                    saleDetails.client?.phone &&
                    masks.cell(saleDetails.client?.phone.ddd + saleDetails.client?.phone.number)
                  }
                />
              </Row>
            </Card>
          </GridItem>
        </Grid>

        <Grid flexDirection='row' gap={25}>
          <GridItem>
            <Card>
              <TitleCard>Dados do Pagamento</TitleCard>

              {saleDetails.type === 'boleto' && (
                <>
                  <ItemElement full title='Código de barras' value={saleDetails.barcode} />

                  <ItemElement
                    full
                    link
                    title='Link Pagamento'
                    value={saleDetails.linkTransaction}
                  />
                  <ItemElement full link title='Link Boleto' value={saleDetails.boletoPdf} />
                </>
              )}
              {saleDetails.type === 'pix' && (
                <>
                  <ItemElement full title='Código de barras' value={saleDetails.qrCodeText} />

                  <ItemElement
                    full
                    link
                    title='Link Pagamento'
                    value={saleDetails.linkTransaction}
                  />
                  <ItemElement full link title='Link Qrcode' value={saleDetails.qrCodePng} />
                </>
              )}
              {saleDetails.type === 'credit' && (
                <>
                  <ItemElement
                    full
                    link
                    title='Link Pagamento'
                    value={saleDetails?.linkTransaction}
                  />
                </>
              )}
              {saleDetails.externalTransactionId && (
                <ItemElement full title='Pagseguro Id' value={saleDetails?.externalTransactionId} />
              )}
              {saleDetails.chargeId && (
                <ItemElement full title='Charge Id' value={saleDetails?.chargeId} />
              )}

              {saleDetails.errorMessage && (
                <>
                  <ItemElement full title='Mensagem de erro' value={saleDetails?.errorMessage} />
                </>
              )}
            </Card>
          </GridItem>
          <GridItem>
            <Card>
              <TitleCard>Histórico Pagamento</TitleCard>
              <Row>
                <ItemElement title='Nome' value={saleDetails.client?.name} />
                <ItemElement
                  title='Documento'
                  value={masks.document(saleDetails.client?.document)}
                />
              </Row>
              <Row>
                <ItemElement title='Email' value={saleDetails.client?.email} />
                <ItemElement
                  title='Telefone'
                  value={
                    saleDetails.client?.phone &&
                    masks.cell(saleDetails.client?.phone.ddd + saleDetails.client?.phone.number)
                  }
                />
              </Row>
            </Card>
          </GridItem>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SaleDetails
