/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { useForm } from 'react-hook-form'
import SelectInput from '../Input/SelectInput'
import { Container, Item } from './styles'
import Company from './Company'
import DateRangeInput from '../Input/DateRangeInput'
import Establishment from './Establishment'
import Client from './Client'

interface StatusClass {
  label: string
  value: string
}

export interface FiltersProps {
  onChange: (filter: any) => void
  statusArray?: StatusClass[]
  tipoArray?: StatusClass[]
  rangeDate?: boolean
  company?: boolean
  client?: boolean
  establishment?: boolean
  filters?: any
}

const Filters: React.FunctionComponent<FiltersProps> = ({
  onChange,
  rangeDate,
  company,
  establishment,
  client,
  statusArray,
  tipoArray,
  filters,
}) => {
  const [myState, setMyState] = React.useState(false)

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>()

  const status = watch('status')
  const type = watch('type')
  const companyValue = watch('company')
  const clientValue = watch('client')
  const establishmentValue = watch('establishment')
  const range = watch('range')

  React.useEffect(() => {
    let payload: any = {}
    if (companyValue) {
      let formatArray = companyValue.map((el: any) => el.value)
      if (formatArray.length > 0) payload.company = JSON.stringify(formatArray)
      else payload.company = undefined
    }
    if (establishmentValue) {
      let formatArray = establishmentValue.map((el: any) => el.value)
      if (formatArray.length > 0) payload.establishment = JSON.stringify(formatArray)
      else payload.establishment = undefined
    }
    if (status) {
      let formatArray = status.map((el: any) => el.value)
      if (formatArray.length > 0) payload.status = JSON.stringify(formatArray)
      else payload.status = undefined
    }

    if (type) {
      let formatArray = type.map((el: any) => el.value)
      if (formatArray.length > 0) payload.type = JSON.stringify(formatArray)
      else payload.type = undefined
    }

    if (clientValue) {
      let formatArray = clientValue.map((el: any) => el.value)
      if (formatArray.length > 0) payload.client = JSON.stringify(formatArray)
      else payload.client = undefined
    }

    if (range) {
      payload.startDate = range?.startDate
      payload.endDate = range?.endDate
    }

    if (!myState) {
      setMyState(true)
      return 
    }

    onChange({
      ...payload,
    })
  }, [status, type, companyValue, range, establishmentValue, clientValue])

  return (
    <Container>
      {rangeDate && (
        <DateRangeInput
          defaulEndDate={filters?.endDate}
          defaultStartDate={filters?.startDate}
          label='Data'
          name='range'
          control={control}
        />
      )}

      {!!client && (
        <Item>
          <Client name={'client'} control={control} />
        </Item>
      )}

      {!!establishment && (
        <Item>
          <Establishment name={'establishment'} control={control} />
        </Item>
      )}

      {!!company && (
        <Item>
          <Company name={'company'} control={control} />
        </Item>
      )}

      {!!tipoArray && (
        <Item>
          <SelectInput isMulti label='Tipo' name={'type'} control={control} options={tipoArray} />
        </Item>
      )}

      {!!statusArray && (
        <Item>
          <SelectInput
            isMulti
            label='Status'
            name={'status'}
            control={control}
            options={statusArray}
          />
        </Item>
      )}
    </Container>
  )
}

export default Filters
