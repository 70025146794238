import yup from '../libs/yup'
import { masks } from '../utils/masks'
import { validarCNPJ, validarCPF } from '../utils/validateDocuments'

export type TaxesDto = {
  credit: number
  debit: number
  debit2: number
  pix: number
  boleto: number
}


export type WebHookDto = {
  sandBoxWebhook?: string
  productionWebhook?: string
}

export type CompanyCreateForm = {
  name: string
  email: string
  phone: string
  type?: string
  description: string
  document: string
  createdAt?: string
  publicKeySandBox?: string
  privateKeySandBox?: string
  publicKeyProduction?: string
  privateKeyProduction?: string
  sandBoxWebhook?: string
  productionWebhook?: string
}
export const taxesEditSchema = yup.object().shape({
  credit: yup.number().required().label('Crédito'),
  debit: yup.number().required().label('Debito'),
  debit2: yup.number().required().label('Debito outras bandeiras'),
  pix: yup.number().required().label('Pix'),
  boleto: yup.number().required().label('Boleto'),
})

export const companyWebhookSchema = yup.object().shape({
  sandBoxWebhook: yup.string().required().label('Webhook de sandbox'),
  productionWebhook: yup.string().required().label('Webhook de produção'),
})

export const companyEditSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  phone: yup.string().required().label('Telefone'),
})

export const companyCreateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().required().email().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  description: yup.string().label('Descrição'),
  document: yup
    .string()
    .test('Documento Inválido', (val: any) => {
      val = masks.unmask(val);
      if (val.length > 12) return validarCNPJ(masks.unmask(val))

      return validarCPF(masks.unmask(val))
    })
    .required(),
})
