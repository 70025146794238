import styled, { css } from 'styled-components'
import fadeIn from '../../../styles/animations/fadeIn'
import ripple from '../../../styles/animations/ripple'

interface FieldProps {
  readonly error: boolean
}

export const Container = styled.div`
  ${fadeIn};
`
export const RemoveButton = styled.div<any>`
  border: 1px ${({ theme }) => theme.colors.border} solid;
  background: white;
  width: 24px;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  z-index: 50;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`

export const NoImageBackground = styled.div<{ src: string; width: number; height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 90%;
    `}
`

export const ImageWrapper = styled.div<{
  borderRadius: number
  src: string
  width: number
  height: number
}>`
  ${ripple}

  cursor: pointer;
  width: 100%;
  justify-content: center;
  border-radius: ${({ borderRadius }) => borderRadius}px;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    `}

  > div {
    transition: 0.1s transform;
  }

  background-color: #fff;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  border: 1px ${({ theme }) => theme.colors.border} solid;
  position: relative;
`
