import yup from "../libs/yup"


export type PixCreateForm = {
  _id?: string
  type: string;
  account: string;
  establishment?: string;
}

export const createPixSchema = yup.object().shape({
  type: yup.string().required().label('Tipo'),
  account: yup.string().required().label('Píx'),
})

export type WidthdrawForm = {
  pix: string;
  value: string;
}

export const WidthdrawSchema = yup.object().shape({
  pix: yup.string().required().label('PIX'),
  value: yup.string().required().label('Valor'),
})

export type ReleaseForm = {
  value: string;
}

export const releaseSchema = yup.object().shape({
  value: yup.string().required().label('Valor'),
})