import React, { ReactComponentElement } from 'react'

import { Content, ModalComponent, TitleHeader } from './styles'
import { IoCloseSharp } from 'react-icons/io5'
import { theme } from '../../../styles/theme'

export interface ModalHandle {
  openModal: () => void
  closeModal: () => void
}

export interface ModalProps {
  title?: string
  maxWidth?: number
  onOpenModal?: () => void
  children?: ReactComponentElement<any>
}

const Modal: React.ForwardRefRenderFunction<ModalHandle, ModalProps> = (
  { title, onOpenModal, maxWidth, children },
  ref,
) => {
  const [open, setOpen] = React.useState(false)

  React.useImperativeHandle(ref, () => ({
    openModal() {
      setOpen(true)
    },
    closeModal() {
      setOpen(false)
    },
  }))

  const afterOpenModal = () => {
    if (onOpenModal) {
      onOpenModal()
    }
  }
  return (
    <ModalComponent
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setOpen(false)}
      contentLabel='Example Modal'
    >
      <Content maxWidth={maxWidth}>
        {title && <TitleHeader>{title}</TitleHeader>}
        <IoCloseSharp color={theme.colors.secondary} onClick={() => setOpen(false)} size={35} />
      </Content>
      {children}
    </ModalComponent>
  )
}

export default React.forwardRef(Modal)
