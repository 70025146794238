import yup from '../libs/yup'

export type UserRegisterForm = {
  name: string
  email: string
  password: string
  confirmPassword: string
}

export type UserForgotChangePasswordForm = {
  email: string
  password: string
  confirmPassword: string
}

export type UserForgotForm = {
  email: string
  password: string
}

export type UserLoginForm = {
  email: string
  password: string
}

export const forgotSchema = yup.object().shape({
  email: yup.string().required().email().label('E-mail'),
})

export const updateUserSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  password: yup.string().label('Senha'),
})

export const forgotChangePasswordSchema = yup.object().shape({
  password: yup.string().required().min(6).max(40).label('Senha'),
  confirmPassword: yup
    .string()
    .label('Confirmar Senha')
    .required()
    .oneOf([yup.ref('password'), ''], 'Senhas devem ser iguais'),
})

export const loginSchema = yup.object().shape({
  email: yup.string().required().email().label('E-mail'),
  password: yup.string().required().min(6).max(40).label('Senha'),
})

export const registerSchema = yup.object().shape({
  name: yup.string().required('Nome'),
  email: yup.string().required().email().label('E-mail'),
  password: yup.string().required().label('Senha').min(6).max(40),
  confirmPassword: yup
    .string()
    .label('Confirmar Senha')
    .required()
    .oneOf([yup.ref('password'), ''], 'Senhas devem ser iguais'),
})
