import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { Card, Container, Grid, GridItem, Title } from '../../../components/commons'
import { api } from '../../../services/api'
import { theme } from '../../../styles/theme'
import { masks } from '../../../utils/masks'
import { CardTitle, CardValue, ContentCard } from './styles'
import { useEstablishmentStore } from '../../../store/establishment'
import { endOfMonth, startOfMonth } from 'date-fns'
import Filters from '../../../components/Filters'

export interface CompanyHomeProps {}

export interface CardHomeProps {
  title: string
  row?: boolean
  value: number
  isLoading?: boolean
}

export const CardHome = ({ title, value, isLoading, row }: CardHomeProps) => {
  return (
    <Card>
      {isLoading ? (
        <ContentCard>
          <ThreeDots
            wrapperStyle={{ justifyContent: 'center' }}
            color={theme.colors.secondary}
            height={30}
          />
        </ContentCard>
      ) : (
        <ContentCard row={!!row}>
          <CardTitle>{title}</CardTitle>
          <CardValue>{masks.currencyAllPlatforms(value)}</CardValue>
        </ContentCard>
      )}
    </Card>
  )
}

const CompanyHome: React.FunctionComponent<CompanyHomeProps> = () => {
  const [loading, setLoading] = React.useState(false)
  const [loadingBalance, setLoadingBalance] = React.useState(false)
  const [filters, setFilters] = React.useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  })
  const [balance, setBalance] = React.useState({
    total: 0,
    pending: 0,
    balanceClient: 0,
  })
  const [establihsmentTax, setEstablihsmentTax] = React.useState([])
  const establisment = useEstablishmentStore.getState().establishment

  const handleChangeFilter = async (filter: any) => {
    setFilters({ ...filter })
  }

  const handleGetBalance = async () => {
    setLoading(true)
    const { data: response } = await api.get(
      establisment ? `/establishments/${establisment._id}/balance` : '/companies/balance',
    )

    setBalance({
      total: response.total,
      pending: response.totalIsPending,
      balanceClient: response.totalClientes,
    })
    setLoading(false)
  }

  const handleGetBalanceClient = async () => {
    setLoadingBalance(true)
    const { data: response } = await api.get('/companies/commission', {
      params: {
        startDate: filters.startDate,
        endDate: filters.endDate,
      }
    })

    setEstablihsmentTax(response)
    setLoadingBalance(false)
  }

  React.useEffect(() => {
    if (!establisment) {
      handleGetBalanceClient()
    }
  }, [filters, establisment])

  React.useEffect(() => {
    handleGetBalance()
  }, [])

  return (
    <Container>
      <Grid flexDirection='row' gap={25}>
        <GridItem style={{ width: 'fit-content' }}>
          <CardHome isLoading={loading} title='Saldo Bloqueado' value={balance.pending} />
        </GridItem>
        <GridItem style={{ width: 'fit-content' }}>
          <CardHome isLoading={loading} title='Saldo' value={balance.total} />
        </GridItem>
        <GridItem style={{ width: 'fit-content' }}>
          <CardHome
            isLoading={loading}
            title='Saldo em conta dos cliente'
            value={balance.balanceClient}
          />
        </GridItem>
      </Grid>
      <Grid flexDirection='column' gap={25}>
        <Title>Comissão recebida por loja</Title>

        <GridItem style={{ display: 'flex' }}>
          <Filters filters={filters} rangeDate onChange={handleChangeFilter} />
        </GridItem>

        {loadingBalance ? (
          <ThreeDots
            wrapperStyle={{ justifyContent: 'center' }}
            color={theme.colors.secondary}
            height={30}
          />
        ) : (
          <Grid flexDirection='row' gap={25}>
            {establihsmentTax.map((item: any) => (
              <GridItem style={{ width: 'fit-content' }}>
                <CardHome
                  isLoading={loadingBalance}
                  title={item.establishment.name}
                  value={item.taxesCompany}
                />
              </GridItem>
            ))}
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default CompanyHome
