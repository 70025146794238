import axios from 'axios'
import { useAuthStore } from '../store/auth'
import { useCompanyStore } from '../store/company'
import { useEstablishmentStore } from '../store/establishment'

const { REACT_APP_API_LOCAL } = process.env
export const api = axios.create({
  baseURL: REACT_APP_API_LOCAL,
  headers: {
    Accept: 'application/json',
  },
})

api.interceptors.request.use(
  async (config) => {
    const token = useAuthStore.getState().token
    const company = useCompanyStore.getState().company

    if(token) {
      config.headers.set('Authorization', `Bearer ${token}`)
    }
    if (company) {
      config.headers.set('x-company-id', company._id)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      useAuthStore.getState().logout()
      useCompanyStore.getState().logout()
      useEstablishmentStore.getState().logout()
    }
    return Promise.reject(error)
  },
)
