import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import React from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../../components/Button'
import { Container, Form, Grid, GridItem, LabelSection } from '../../../../components/commons'
import Input from '../../../../components/Input'
import useToast from '../../../../hooks/useToast'
import { api } from '../../../../services/api'
import { useCompanyStore } from '../../../../store/company'
import { masks } from '../../../../utils/masks'
import { CompanyCreateForm, companyEditSchema } from '../../../../validators/company.schema'
import { GridCard, ContentFooter } from '../styles'
import { useEstablishmentStore } from '../../../../store/establishment'

export interface CompanyDataProps {}

const CompanyData: React.FunctionComponent<CompanyDataProps> = () => {
  const [loading, setLoading] = React.useState(false)
  const establishment = useEstablishmentStore.getState().establishment

  const company = useCompanyStore.getState().company || establishment

  const toast = useToast()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyCreateForm>({
    defaultValues: {
      ...company,
      phone: masks.cell(company?.phone),
      document: masks.document(company?.document),
      createdAt: company?.createdAt && format(new Date(company?.createdAt), 'dd/MM/yyyy HH:mm'),
    },
    resolver: yupResolver(companyEditSchema),
  })

  const handleSubmitLogin = async (formData: CompanyCreateForm) => {
    try {
      setLoading(true)

      const { data: response } = await api.put('/companies', {
        name: formData.name,
        phone: formData.phone,
      })

      useCompanyStore.getState().setCompany(response)
      toast.successToast('Empresaatualizada com sucesso !')
    } catch (error) {
      toast.errorToast('Erro ao editar empresa !')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(handleSubmitLogin)}>
        <GridCard flexDirection='column' gap={25}>
          <LabelSection>Dados cadastrais</LabelSection>
          <Grid flexDirection='row' gap={25}>
            <GridItem>
              <Input
                placeholder='Digite seu nome'
                error={errors?.name}
                control={control}
                label='Nome'
                type='text'
                name='name'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite seu Email'
                error={errors?.email}
                control={control}
                disabled
                label='Email'
                type='email'
                name='email'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite seu Telefone'
                error={errors?.phone}
                control={control}
                label='Telefone'
                type='text'
                mask='cell'
                name='phone'
              />
            </GridItem>
          </Grid>
          <Grid flexDirection='row' gap={25}>
            <GridItem>
              <Input
                placeholder='Digite seu Documento'
                error={errors?.document}
                control={control}
                label='Documento'
                disabled
                type='text'
                mask='document'
                name='document'
              />
            </GridItem>

            <GridItem>
              <Input
                placeholder='Digite seu Telefone'
                error={errors?.createdAt}
                control={control}
                label='Data de Criação'
                disabled
                type='text'
                name='createdAt'
              />
            </GridItem>
          </Grid>
          <ContentFooter>
            <Button
              color='secondary'
              loading={loading}
              type='submit'
              variantType='block'
              label='Salvar'
            />
          </ContentFooter>
        </GridCard>
      </Form>
    </Container>
  )
}

export default CompanyData
