import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import ripple from '../../../styles/animations/ripple'

export const Container = styled.button`
  ${ripple};

  background-color: transparent;
  -webkit-appearance: none;
  align-items: center;
  display: flex;

  transition: background-color 200ms linear, box-shadow 200ms linear, border-color 200ms linear;
  border: 0;
  padding: 5px;

  width: 100%;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;

      opacity: 60%;
    `}
`

export const Label = styled.div<{ active: boolean }>`
  text-align: left;
  font-size: 16px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};

  ${down('md')} {
    font-size: 25px;
  }
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.secondary};
      border-radius: 4px;
    `}
`
